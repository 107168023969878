import produce from 'immer';

import { SET_PROFILE, CLEAR_PROFILE, SET_ERRORS, CLEAR_ERRORS } from '../actions/profile.action'

const initial_state = {
    profile: null,
    errors: null
}

const profileReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_PROFILE:
                draft.profile = action.profile;
                break;
            case CLEAR_PROFILE:
                draft.profile = null;
                break;
            case SET_ERRORS:
                draft.errors = action.errors;
                break;
            case CLEAR_ERRORS:
                draft.errors = null;
                break;
            default: return draft;
        }
    })
}

export default profileReducer;