import produce from 'immer';

import { SET_CATEGORIES } from '../actions/categoryTree.actions'

const initial_state = {
    categories: {}
}

const categoryTreeReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_CATEGORIES:
                draft.categories = action.categories
                break;
            default: return draft;
        }
    })
}

export default categoryTreeReducer;