import React, { Component } from 'react';
import moment from "moment";
import ComponentNavbar from '../../application/components/componentNavbar';
import './generator.edit.css';
import { Redirect } from 'react-router-dom';

export default class GeneratorEditComponent extends Component {

    state = {
        noSeries: true,
        selectedProduct: null
    }

    componentDidMount() {
        const { match: { params: { id } }, getCode } = this.props;
        if (id) {
            getCode(id);
        }
    }

    handleSaveCodeButton = () => {
        const { saveCode } = this.props;
        saveCode();
    }

    changeField = (name, value) => {
        const { changeField } = this.props;
        changeField(name, value);
    }

    renderHistory = () => {
        const { code: { createdAt, history } } = this.props;
        if (history instanceof Array && history.length) {
            return (<ul className="timeline">
                {history.slice(0).reverse().map((h, i) => (<li key={i}>
                    <span className="text-primary">{moment(h.createdAt).format("DD.MM.YYYY HH:mm")} - {h.creator.name || "-"}</span>
                    <p>Zmieniono datę ważności z <b>{moment(h.expirationDateBefore).format("DD.MM.YYYY HH:mm")}</b> na <b>{moment(h.expirationDateAfter).format("DD.MM.YYYY HH:mm")}</b></p>
                </li>))}
                <li>
                    <span className="text-primary">{moment(createdAt).format("DD.MM.YYYY HH:mm")}</span>
                    <p>Kod został utworzony</p>
                </li>
            </ul>)
        }
        return <p className='text-center'>Brak historii do wyświetlenia</p>
    }

    render() {
        const { code: { seriesNr, eanNr, expirationDate, nettoWeight, tareWeight, name }, saved } = this.props;

        if (saved) {
            return <Redirect to="/generator/list" />
        }

        return (
            <div className="d-flex flex-column container product-container">
                <ComponentNavbar
                    title="Edycja kodu"
                />
                <div className="row mt-5">
                    <div className='col-6'>
                        <div className="col-12 mt-3">
                            <label>Numer EAN</label>
                            <input className="form-control" name="eanNr" placeholder="Wpisz numer EAN" defaultValue={eanNr} disabled={true} />

                        </div>
                        <div className="col-12 mt-3">
                            <label>Numer serii</label>
                            <input className="form-control" name="seriesNr" placeholder="Wpisz numer serii" defaultValue={seriesNr} disabled={true} />

                        </div>
                        <div className="col-12 mt-3">
                            <label>Waga netto</label>
                            <input type="number" className="form-control" defaultValue={nettoWeight} disabled={true} />
                        </div>
                        <div className="col-12 mt-3">
                            <label>Tara</label>
                            <input type="number" className="form-control" defaultValue={tareWeight} disabled={true} />
                        </div>
                        <div className="col-12 mt-3">
                            <label>Data ważności</label>
                            <input type="date" className="form-control" value={moment(expirationDate).format("YYYY-MM-DD")} onChange={(e) => this.changeField("expirationDate", e.target.value)} />
                        </div>
                        <div className="col-12 mt-3">
                            <div className="alert alert-warning" role="alert">
                                Zapisanie kodu spowoduje zmianę dat ważności produktu <strong>{name}</strong> o serii <strong>{seriesNr}</strong> na wszystkich pozycjach magazynowych!
                            </div>
                            <div className="mb-5 mt-3 text-center">
                                <button onClick={this.handleSaveCodeButton} type="button" className="btn btn-primary">Zapisz</button>
                            </div>
                        </div>
                    </div>

                    <div className='col-6'>
                        <h6 className='text-center'>Historia edycji</h6>
                        {this.renderHistory()}
                    </div>
                </div>

            </div>
        )

    }

    componentWillUnmount() {
        const { clearCode, clearErrors } = this.props;
        clearCode();
        clearErrors();
    }

}