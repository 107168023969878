import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { css } from 'glamor';
import 'react-toastify/dist/ReactToastify.css';

import AuthContainer from '../../auth/containers/auth.container';
import ContentContainer from './content.container';

//actions
import { isAuth } from '../../auth/actions/auth.actions';

class AppContainer extends React.Component {
    componentDidMount = () => {
        this.props.isAuth();
    }

    renderContent = () => {
        if (this.props.auth.isAuth) {
            return (
                <div>
                    <ContentContainer />
                </div>
            )
        } else {
            return (
                <AuthContainer />
            )
        }
    }
    render() {
        return (
            <div>
                <ToastContainer
                    position={toast.POSITION.BOTTOM_LEFT}
                    autoClose={3000}
                    draggable={false}
                    hideProgressBar={true}
                    bodyClassName={
                        css({
                            fontSize: '1rem',
                            color: 'black',
                        })
                    } />
                {this.renderContent()}
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        isAuth: () => {
            dispatch(isAuth())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);