import React from 'react';
import { connect } from 'react-redux';
import { clearDashboard, getDashboard, deleteShortcut } from '../actions/dashboard.actions';
import Dashboard from '../components/dashboard.component';

class DashboardContainer extends React.Component {
    render() {
        return (
            <div>
                <Dashboard {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        dashboard: store.dashboard.dashboard
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDashboard: () => {
            dispatch(getDashboard());
        },
        clearDashboard: () => {
            dispatch(clearDashboard());
        },
        deleteShortcut: (shortcutId) => {
            dispatch(deleteShortcut(shortcutId));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);