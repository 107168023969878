import React from 'react';
import { Link } from "react-router-dom";

export default class ProductListItem extends React.Component {
    render() {
        const {
            product: {
                _id,
                name,
            },
        } = this.props;

        return (
            <Link
                className="list-group-item list-group-item-action"
                // to={`${parentUrl}/${_id}`}
                to={`/products/details/${_id}`}
            >
                {name}
            </Link>

        )
    }
}