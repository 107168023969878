import React from 'react';
import { Redirect } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import Autocomplete from '../../application/containers/autocomplete.container';
import "../../index.css";

export default class Inventory extends React.Component {

    componentDidMount() {
        const { match: { params: { id } }, getInventory } = this.props;
        if (id) {
            getInventory(id);
        }
    }

    renderForm = () => {
        const { inventory, errors } = this.props;
        return (
            <div className="row mt-5">
                <div className="col-lg-6">
                    <form>
                        <div className="form-group">
                            <label>Nazwa inwentaryzacji</label>
                            <input name="name" type="text" className="form-control" value={inventory.name} onChange={(e) => this.changeField("name", e)} placeholder="Wpisz nazwę inwentaryzacji" />
                            {errors.name && <p className="text-danger">{errors.name}</p>}
                        </div>
                    </form>
                </div>
                <div className="col-lg-6">
                    <label>Magazyn</label>
                    {inventory.warehouse && (
                        <h6>
                            <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.setFieldValue("warehouse", null)}>
                                {inventory.warehouse}
                                {"  "}
                                <button className="btn btn-sm btn-secondary">X</button>
                            </span>
                        </h6>
                    )}
                    <Autocomplete onSelect={(value) => this.setFieldValue("warehouse", value.code)} content="warehouse" name="inventoryWarehouse" />
                    {errors.warehouse && <p className="text-danger">{errors.warehouse}</p>}
                </div>
            </div>

        );
    }

    changeField = (name, e) => {
        const { changeField } = this.props;
        changeField(name, e.target.value);
    }

    setFieldValue = (name, value) => {
        const { changeField } = this.props;
        changeField(name, value);
    }

    saveInventory = () => {
        const { saveInventory } = this.props;
        saveInventory();
    }

    render() {
        const { saved } = this.props;

        if (saved) {
            return <Redirect to="/inventories" />
        }
        const form = this.renderForm();
        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Dodawanie inwentaryzacji"}
                    button={null}
                />
                {form}
                <div className="justify-content-center mb-5 mt-5 text-center">
                    <button className="btn btn-primary" type="button" onClick={() => { this.saveInventory() }}>Zapisz</button>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearInventory, clearErrors } = this.props;
        clearInventory();
        clearErrors();
    }
}