import React from 'react';

export default class BasicInput extends React.Component {
    renderError = () => {
        const { error } = this.props;

        if (error) return <div><span className="text-danger">{error}</span></div>

        return null;
    }

    render() {
        return (
            <div className={this.props.className}>
                <label
                // className="cms-item__label"
                >
                    {this.props.label}
                </label>

                <input
                    // className="cms-item__input cms-input__label"
                    className="form-control"
                    type={this.props.type}
                    id={this.props.id}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    value={this.props.value}
                    required={this.props.required ? true : false}
                    disabled={this.props.disabled ? true : false} />

                {this.renderError()}
            </div>

        )
    }
}