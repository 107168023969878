import Api from '../../services/api';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { isLoading } from '../../application/actions/application.actions'

const namespace = 'DASHBOARD';
export const SET_DASHBOARD = `SET_DASHBOARD_${namespace}`;
export const CLEAR_DASHBOARD = `CLEAR_DASHBOARD_${namespace}`;

export const setDashboard = (dashboard) => (dispatch) => {
    dispatch({
        type: SET_DASHBOARD,
        dashboard: dashboard
    });
}

export const clearDashboard = () => (dispatch) => {
    dispatch({
        type: CLEAR_DASHBOARD
    });
}

export const getDashboard = () => async (dispatch) => {

    dispatch(isLoading(true));

    let res = await Api.get("/application/dashboard");

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setDashboard(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}


export const saveShortcut = (name, path) => async (dispatch) => {

    dispatch(isLoading(true));

    let res = await Api.get("/application/dashboard");

    dispatch(isLoading(false));

    if (res && res.success) {
        let dashboard = res.document;
        if (!dashboard.shortcuts) {
            dashboard.shortcuts = [];
        }
        dashboard.shortcuts.push({ name: name, path: path });

        let res2 = await Api.put(`/application/dashboard/${dashboard._id}`, dashboard);

        if (res2 && res2.success) {
            toast('Zapisano skrót');
        } else {
            toast('Wystąpił błąd');
        }
    } else {
        toast('Wystąpił błąd');
    }
}

export const deleteShortcut = (shortcutId) => async (dispatch, getState) => {
    const { dashboard: { dashboard } } = getState();

    dispatch(isLoading(true));
    let newDashobard = JSON.parse(JSON.stringify(dashboard));
    newDashobard.shortcuts = _.reject(newDashobard.shortcuts, (shortcut) => shortcut._id === shortcutId);

    let res = await Api.put(`/application/dashboard/${dashboard._id}`, newDashobard);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setDashboard(newDashobard));
        toast('Usunięto skrót');
    } else {
        toast('Wystąpił błąd');
    }
}

