import { toast } from 'react-toastify';
import _ from 'lodash';

import Api from '../../services/api';


const namespace = 'SYMFONIA_IMPORT';
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_DOCUMENTS = `CLEAR_DOCUMENTS_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;

const isLoading = () => dispatch => {
    dispatch({
        type: IS_LOADING
    })
}

const parseImport = (documents, data) => dispatch => {
    let errors = [];
    let success = true;

    _.each(documents, (doc, i) => {
        let document = {
            correct: true,
            products: []
        };

        const err = doc.err;

        if (doc.imported === false) {

            if (err) {
                document.correct = false;
                success = false;
                document.document = data[i].kod;

                if (err.warehouseFrom) {
                    document.warehouseFrom = {};
                    document.warehouseFrom.err = err.warehouseFrom;
                    document.warehouseFrom.warehouse = data[i].kodMag;
                }

                if (err.warehouseTo && data[i].kodKH) {
                    document.warehouseTo = {};
                    document.warehouseTo.err = err.warehouseTo;
                    document.warehouseTo.warehouse = data[i].kodKH;
                }

                if (err.products) {
                    _.each(err.products, (prod, j) => {
                        let product = {
                            exists: true
                        };

                        
                        if (prod.productId || prod.ean) {
                            product.exists = false;
                            // document.products.push(product);
                        }

                        if (Array.isArray(data[i]['Pozycja dokumentu'])) {
                            if (data && data[i] && data[i]['Pozycja dokumentu'] && data[i]['Pozycja dokumentu'][j] && data[i]['Pozycja dokumentu'][j].kod) {
                                product.code = data[i]['Pozycja dokumentu'][j].kod;
                            }
                        } else {
                            product.code = data[i]['Pozycja dokumentu'].kod;
                        }

                        product = _.merge(product, prod);

                        document.products.push(product);
                    })
                }

            }
        } else {
        }

        errors.push(document);

    })

    dispatch({
        type: SET_ERRORS,
        errors
    })

    return success;
}

const clearDocuments = () => dispatch => {
    dispatch({
        type: CLEAR_DOCUMENTS
    })
}

export const uploadDocument = (file) => dispatch => {

    dispatch(isLoading(true));

    dispatch(clearDocuments());

    let formData = new FormData();
    formData.append('file', file);

    Api.post('/document/import/symfoniaImport', formData, true, true).then(res => {
        dispatch(isLoading(false));

        if (res.success) {
            const documents = res.documents;
            const data = res.data;
            const success = dispatch(parseImport(documents, data));
            if (success) {
                toast('Dokument zaimportowany')
            } else {
                toast('Dokument nie został zaimportowany');
            }
        } else {
            toast('Dokument nie został zaimportowany');
        }
    }).catch(err => {
        console.error(err)
    })
}