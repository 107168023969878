import React from 'react';
import { connect } from 'react-redux';
import { getRoles, clearRoles, setFilters, resetFilters, setOffset } from '../actions/roles.action'
import Roles from '../components/roles.component';

class RolesContainer extends React.Component {
    render() {
        return (
            <div>
                <Roles {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        roles: store.roles.roles,
        total: store.roles.total,
        isAdmin: store.auth.isAdmin,
        filters: store.roles.filters,
        offset: store.roles.offset,
        limit: store.roles.limit
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRoles: () => {
            dispatch(getRoles());
        },
        clearRoles: () => {
            dispatch(clearRoles());
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        setOffset: (offset) => {
            dispatch(setOffset(offset));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesContainer);