import React from 'react';
import { connect } from 'react-redux';
import { changeField, clearReport, generateReport, setReport } from '../actions/report.actions';
import Report from '../components/report.component';

class ReportContainer extends React.Component {
    render() {
        return (
            <div>
                <Report {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        report: store.report.report,
        errors: store.report.errors,
        isAdmin: store.auth.isAdmin
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearReport: () => {
            dispatch(clearReport());
        },
        generateReport: () => {
            dispatch(generateReport());
        },
        changeField: (name, value) => {
            dispatch(changeField(name, value));
        },
        setReport: (report) => {
            dispatch(setReport(report));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);