import produce from 'immer';

import { SET_ITEMS, CLEAR_ITEMS, SET_ITEM, CLEAR_ITEM, SET_OFFSET } from '../actions/administration.actions'

const initial_state = {
    items: [],
    total: 0,
    item: null,
    total: 0,
    offset: 0,
    limit: 25
}

const administrationReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_ITEMS:
                draft.items = action.items;
                draft.total = action.total;
                break;
            case CLEAR_ITEMS:
                draft.items = [];
                draft.total = 0;
                break;
            case SET_ITEM:
                draft.item = action.item;
                break;
            case CLEAR_ITEM:
                draft.item = null;
                break;
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            default: return draft;
        }
    })
}

export default administrationReducer;