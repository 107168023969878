import produce from "immer";
import moment from 'moment';

import {
    SET_PRODUCT,
    CLEAR_PRODUCT,
    SET_ERRORS,
    CLEAR_ERRORS,
    SET_PRODUCT_HISTORY,
    CLEAR_PRODUCT_HISTORY,
    SET_HISTORY_FILTERS,
    SET_HISTORY_OFFSET,
    RESET_HISTORY_FILTERS,
    SET_PRODUCT_RESERVATIONS,
    CLEAR_PRODUCT_RESERVATIONS
} from '../actions/product.actions';

const productDefaultState = {
    product: {
        gratis: false,
        active: true,
        PAO: false
    },
    errors: {},
    history: {
        items: null,
        filters: {
            seriesNr: "",
            documentNumber: "",
            positionCode: "",
            dateFrom: moment().startOf('year').toISOString(),
            dateTo: moment().endOf('day').toISOString()
        },
        limit: 10,
        offset: 0,
        total: 0
    },
    reservations: []
}

const productReducer = (state = productDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_PRODUCT: {
                draft.product = action.product;
                break;
            }
            case CLEAR_PRODUCT:
                draft.product = {
                    gratis: false,
                    active: true,
                    PAO: false
                };
                break;
            case SET_ERRORS:
                draft.errors = action.err;
                break;
            case CLEAR_ERRORS:
                draft.errors = {};
                break;
            case SET_PRODUCT_HISTORY:
                draft.history.items = action.items;
                draft.history.total = action.total;
                break;
            case CLEAR_PRODUCT_HISTORY:
                draft.history.items = null;
                draft.history.total = 0;
                break;
            case SET_HISTORY_OFFSET:
                draft.history.offset = action.offset;
                break;
            case SET_HISTORY_FILTERS:
                draft.history.filters[action.filter] = action.value;
                draft.history.offset = 0;
                break;
            case RESET_HISTORY_FILTERS:
                draft.history.filters = {
                    seriesNr: "",
                    documentNumber: "",
                    positionCode: "",
                    dateFrom: moment().startOf('year').toISOString(),
                    dateTo: moment().endOf('day').toISOString()
                };
                draft.history.offset = 0;
                break;
            case SET_PRODUCT_RESERVATIONS:
                draft.reservations = action.reservations;
                break;
            case CLEAR_PRODUCT_RESERVATIONS:
                draft.reservations = [];
                break;
            default: {
                return draft;
            }
        }
    });

}

export default productReducer;