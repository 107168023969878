import React from 'react';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import Pagination from '../../application/components/pagination';
import Acl from '../../services/acl.service';
import DictManager from "../../services/dict.service";
import ProductListItem from './products.listItem';
import CategoryTree from "../../application/containers/categoryTree.container";

export default class Products extends React.Component {
    state = {
        name: '',
        ean: '',
        page: 1
    }

    componentDidMount = () => {
        const { getProducts, resetFilters } = this.props;
        resetFilters();
        getProducts();
        this.setState({
            name: this.props.products.filters.name,
            ean: this.props.products.filters.ean,
        })
    }

    componentWillUnmount = () => {
        const { resetFilters } = this.props;
        resetFilters();
    }

    setCategoriesFilter(checked) {
        if (checked.length === 0) {
            this.props.setFilters('categories', undefined);
        } else {
            this.props.setFilters('categories', checked.map((c) => c._id));
        }
        this.props.getProducts();
    }

    navbarButton = () => {
        const { isAdmin } = this.props.auth;

        return (
            <div className="btn-group">
                {Acl.isAllowedAction('product', 'category', 'get', isAdmin) && <CategoryTree content="productCategory" id="productCategory" onCheck={(checked) => this.setCategoriesFilter(checked)} />}
                {
                    Acl.isAllowedAction('product', 'product', 'create', isAdmin) && <Link to={`products/product`}>
                        <button
                            // style={{ width: '150px' }}
                            type='button'
                            className='btn btn-outline-primary ml-1'>Dodaj</button>
                    </Link>
                }
            </div>

        )


    }

    handleInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({
            [name]: value
        }, () => {
            this.props.setFilters('name', this.state.name);
            this.props.setFilters('ean', this.state.ean);
            this.props.getProducts();
        })
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        this.props.setFilters('name', this.state.name);
        this.props.setFilters('ean', this.state.ean);
        this.props.getProducts();
    }

    changeFilter = (e, name) => {
        const { setFilters, getProducts } = this.props;
        let value = e.target.value;
        if (["type", "gratis", "PAO", "active"].indexOf(name) > -1 && value === "0") {
            value = undefined;
        }
        setFilters(name, value);
        this.setState({ page: 1 });
        getProducts();
    }

    resetFilters = () => {
        const { resetFilters, getProducts } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getProducts();
    }

    renderSearchBar = () => {
        const { products: { filters } } = this.props;
        let options = DictManager.getOptionsByType(1);
        return (
            <div className="mb-4">
                <div className="row">
                    <div className="col-md-3">
                        <label>Nazwa</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę" value={filters.name} onChange={(e) => this.changeFilter(e, 'name')} />
                    </div>
                    <div className="col-md-3">
                        <label>Ean</label>
                        <input type="text" className="form-control" placeholder="Wpisz ean" value={filters.ean} onChange={(e) => this.changeFilter(e, 'ean')} />
                    </div>
                    <div className="col-md-3">
                        <label>Kod</label>
                        <input type="text" className="form-control" placeholder="Wpisz kod" value={filters.code} onChange={(e) => this.changeFilter(e, 'code')} />
                    </div>
                    <div className="col-md-3">
                        <label>Typ</label>
                        <select className="form-control" value={filters.type || "0"} onChange={(e) => this.changeFilter(e, 'type')} >
                            {options}
                        </select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-3">
                        <label>Aktywny</label>
                        <select className="form-control" value={filters.active || "0"} onChange={(e) => this.changeFilter(e, 'active')} >
                            <option value="0">Wybierz</option>
                            <option value="true">Tak</option>
                            <option value="false">Nie</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>Gratis</label>
                        <select className="form-control" value={filters.gratis || "0"} onChange={(e) => this.changeFilter(e, 'gratis')} >
                            <option value="0">Wybierz</option>
                            <option value="true">Tak</option>
                            <option value="false">Nie</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <label>PAO</label>
                        <select className="form-control" value={filters.PAO || "0"} onChange={(e) => this.changeFilter(e, 'PAO')} >
                            <option value="0">Wybierz</option>
                            <option value="true">Tak</option>
                            <option value="false">Nie</option>
                        </select>
                    </div>
                    <div className="col-md-3 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>
            </div>);
    }

    setPage = (page) => {
        this.setState({ page: page });
    }

    navigationButtons = () => {
        const { products: { offset, productsPerPage, total }, changeOffset, getProducts } = this.props;
        const { page } = this.state;

        if (total > 0) {
            return (
                <Pagination
                    getDocuments={getProducts}
                    changeOffset={changeOffset}
                    offset={offset}
                    total={total}
                    perPage={productsPerPage}
                    setPage={this.setPage}
                    page={page}
                />
            )
        }
    }

    singleListProduct = (products) => {
        return products.map(product => {
            return (
                <ProductListItem
                    key={product._id}

                    product={product}
                    parentUrl={this.props.match.url}
                />
            )
        })
    }

    productList = () => {
        const { productList } = this.props.products;

        if (productList && productList.length > 0) {
            return (
                <ul className="list-group product-list">
                    {this.singleListProduct(productList)}
                </ul>
            )

        } else {
            return (
                <ul className="d-flex justify-content-center">
                    <div>Brak produktów</div>
                </ul>
            )
        }
    }

    render() {
        const searchBar = this.renderSearchBar();

        return (
            <div className="d-flex flex-column container products-container">
                <ComponentNavbar
                    title="Produkty"
                    button={this.navbarButton()}
                />
                {/* {this.productsNavbar()} */}
                {searchBar}

                {this.navigationButtons()}
                {/* Product list */}
                {this.productList()}
                {this.navigationButtons()}
            </div>
        )
    }
}