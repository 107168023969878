import React from 'react';
import SimpleModal from "./simpleModal.component";
import { toast } from 'react-toastify';
const _ = require("lodash");

export default class CategoryTree extends React.Component {

    state = {
        selected: null,
        checked: this.props.checked instanceof Array ? this.props.checked : [],
        open: [],
        form: {
            name: ""
        }
    }

    componentWillReceiveProps(nextProps) {
        const { checked } = this.props;
        if (_.isEmpty(checked) && !_.isEmpty(nextProps.checked)) {
            let newOpen = [];
            _.each(nextProps.checked, (c) => {
                if (c.ancestors instanceof Array) {
                    newOpen = _.concat(newOpen, c.ancestors);
                }
            });
            this.setState({ checked: nextProps.checked, open: _.uniq(newOpen) });
        }
    }

    renderTree(categories) {
        const { checked, selected, open } = this.state;
        if (!categories) {
            return <p className="text-center">Brak elementów</p>;
        }
        return categories.map((category) => {
            let hasChildren = !_.isEmpty(category.children);
            let isChecked = _.findIndex(checked, (c) => c._id === category._id) > -1;
            let isSelected = selected && selected._id === category._id;
            let isOpen = open.indexOf(category._id) > -1;
            return <div className="d-flex flex-row p-0 m-0" key={category._id}>
                <div className="text-right p-0">
                    {hasChildren && <i className={`fa ${isOpen ? 'fa-chevron-down' : 'fa-chevron-right'}`} onClick={() => { this.setState({ open: _.xor(open, [category._id]) }) }}></i>}
                </div>
                {hasChildren && <div className="text-right p-0 ml-1">
                    <i className="fa fa-list" onClick={() => this.selectInChild(category, isChecked)}></i>
                </div>}
                <div className="pl-2" style={{ marginTop: '-2px' }}>
                    <div className="form-check pb-1">
                        <input className="form-check-input" type="checkbox" checked={isChecked} id={category._id} onChange={() => this.onCategoryCheck(category)} />
                        <label className={`form-check-label ${isSelected && 'text-primary'}`} onClick={() => this.onCategorySelect(category)}>
                            {category.name}
                        </label>
                    </div>
                    {hasChildren && isOpen && <div>
                        {this.renderTree(category.children)}
                    </div>}
                </div>
            </div>
        });
    }

    renderForm(action) {
        const { id } = this.props;
        const { selected, form } = this.state;
        return <div>
            <div className="form-group">
                <label>Nazwa</label>
                <input
                    type="text"
                    className="form-control"
                    placeholder="Wpisz nazwę"
                    id={`${id}name`}
                    defaultValue={action === "add" ? "" : selected ? selected.name : ""}
                    onChange={(e) => this.setState({ form: Object.assign({}, form, { name: e.target.value }) })}
                />
            </div>
            <p>Kategoria nadrzędna: {selected ? selected.name : "-"}</p>
        </div>;
    }

    renderContent() {
        const { categories = {}, auth, id } = this.props;
        let isAdmin = _.get(auth, "isAdmin", false);
        const { selected } = this.state;
        const tree = this.renderTree(categories[id]);
        return <div className="row">
            <div className="col-12 text-right">
                {selected && isAdmin && <button type="button" className="btn btn-danger btn-sm mr-1" data-target={`#${id}delete`} data-toggle="modal" onClick={() => this.changeMainModalVisibility(false)}>Usuń</button>}
                {selected && isAdmin && <button type="button" className="btn btn-primary btn-sm mr-1" data-target={`#${id}edit`} data-toggle="modal" onClick={() => this.setForm("edit")}>Edytuj</button>}
                {isAdmin && <button type="button" className="btn btn-primary btn-sm" data-target={`#${id}add`} data-toggle="modal" onClick={() => this.setForm("add")}>Dodaj</button>}
            </div>
            <div className="col-12 mt-2">
                {tree}
            </div>
        </div>
    }

    setForm(action) {
        const { selected } = this.state;
        if (action === "add") {
            this.setState({ form: { parent: selected ? selected._id : null, name: "" } });
        } else {
            this.setState({ form: selected })
        }
        this.changeMainModalVisibility(false);
    }

    onCategorySelect(category) {
        const { selected } = this.state;
        if (selected && selected._id === category._id) {
            this.setState({ selected: null });
        } else {
            this.setState({ selected: category });
        }
    }

    onCategoryCheck(category) {
        const { onCheck } = this.props;
        const { checked } = this.state;
        let newChecked = _.xorBy(checked, [category], (c) => c._id);
        this.setState({ checked: newChecked });

        if (_.isFunction(onCheck)) {
            onCheck(newChecked);
        }
    }

    handleSaveCategory(action) {
        const { saveCategory, content, id } = this.props;
        const { form } = this.state;
        if (_.trim(form.name).length > 0) {
            saveCategory(content, id, form);
            document.getElementById(`${id}name`).value = "";
            document.getElementById(`${id}${action}-cancel`).click();
            this.setState({
                selected: null,
                form: {
                    name: ""
                }
            });
        } else {
            toast("Wpisz nazwę kategorii");
        }
    }

    handleDeleteCategory() {
        const { deleteCategory, content, id } = this.props;
        const { selected } = this.state;
        deleteCategory(content, selected._id, id);
        this.changeMainModalVisibility(true);
        this.setState({
            selected: null,
            form: {
                name: ""
            }
        });
    }

    changeMainModalVisibility(visible) {
        const { id } = this.props;
        // document.getElementById(id).style.display = visible ? "block" : "none";
    }

    selectInChild(category, isChecked) {
        const { onCheck } = this.props;
        const { checked, open } = this.state;

        let newChecked = this.getInChild(category.children);
        newChecked.push(category);
        let newOpen = newChecked.map((c) => c._id);
        if (isChecked) {
            newChecked = _.pullAllBy(checked, newChecked, "_id");
            newOpen = _.pullAll(open, newOpen);
        } else {
            newChecked = _.uniq(_.concat(checked, newChecked));
            newOpen = _.uniq(_.concat(open, newOpen));
        }

        this.setState({
            checked: newChecked,
            open: newOpen
        });
        if (_.isFunction(onCheck)) {
            onCheck(newChecked);
        }
    }

    getInChild(children) {
        let newChecked = [];
        _.each(children, (child) => {
            if (!_.isEmpty(child.children)) {
                newChecked = _.concat(newChecked, [_.omit(child, ["children"])], this.getInChild(child.children));
            } else {
                newChecked.push(_.omit(child, ["children"]));
            }
        });
        return newChecked;
    }

    resetChecked() {
        const { onCheck } = this.props;
        this.setState({ checked: [], open: [] });

        if (_.isFunction(onCheck)) {
            onCheck([]);
        }
    }

    render() {
        const { buttonText, getCategoryTree, id, content, buttonClass } = this.props;
        const { checked } = this.state;
        const tree = this.renderContent();
        return <div>
            <button type="button" className={`btn btn-secondary ${buttonClass ? buttonClass : ''}`} data-target={`#${id}`} data-toggle="modal" onClick={() => getCategoryTree(content, id)}>
                {buttonText || "Kategorie"}
            </button>
            {!_.isEmpty(checked) && <small><br />{checked.length} zaznaczono</small>}
            <SimpleModal
                title={"Wybierz kategorie"}
                content={tree}
                id={id}
                button={<button type="button" className="btn btn-primary" onClick={() => this.resetChecked()}>Odznacz wszystkie</button>}
            />
            <SimpleModal
                title="Dodawanie kategorii"
                content={this.renderForm("add")}
                id={`${id}add`}
                button={<button type="button" className="btn btn-primary" onClick={() => this.handleSaveCategory("add")}>Zapisz</button>}
                onCancel={() => this.changeMainModalVisibility(true)}
            />
            <SimpleModal
                title="Edycja kategorii"
                content={this.renderForm("edit")}
                id={`${id}edit`}
                button={<button type="button" className="btn btn-primary" onClick={() => this.handleSaveCategory("edit")}>Zapisz</button>}
                onCancel={() => this.changeMainModalVisibility(true)}
            />
            <SimpleModal
                title="Usuwanie kategorii"
                content={<p>Czy napewno chcesz usunąć wybraną kategorię i wszytkie jej podkategorie?</p>}
                id={`${id}delete`}
                button={<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={() => this.handleDeleteCategory()}>Usuń</button>}
                onCancel={() => this.changeMainModalVisibility(true)}
            />
        </div>
    }

    componentWillUnmount() {
        const { clearCategoryTree, id } = this.props;
        clearCategoryTree(id);
    }
}
