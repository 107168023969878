import _ from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';
import CategoryTree from "../../application/containers/categoryTree.container";
import ComponentNavbar from '../../application/components/componentNavbar';
import DictManager from "../../services/dict.service";

export default class Prodouct extends React.Component {

    state = {
        isEdit: false,
        redirect: false
    }

    componentDidMount() {
        const { match: { params: { id } }, getProduct } = this.props;

        if (id) {
            this.setState({
                isEdit: true
            });
            getProduct(id);
        }
    }

    navbarButton = () => {
        return (
            <button
                type='button'
                className='btn btn-danger'
                onClick={this.handleOpenModal}
            > Usuń produkt </button>
        )
    }

    productsNavbar = () => {
        const { id } = this.props.match.params;
        let title = "Dodawanie produktu";
        if (id) {
            title = "Edycja produktu";
        }

        return (
            <ComponentNavbar
                title={title}
                button={null}
            />
        )
    }

    handleOnSuccess = () => {
        this.setState({
            redirect: true
        })
    }

    changeField = (name, e) => {
        const { changeField } = this.props;
        let value = e.target.value;
        if (name === "pkwiu") {
            value = _.replace(value, ",", ".", "g");
        }
        if (["type", "unit"].indexOf(name) > -1 && value == 0) {
            value = undefined;
        }
        changeField(name, value);
    }

    changeCheckbox = (name, e) => {
        const { changeField } = this.props;
        changeField(name, e.target.checked);
    }

    saveProduct = () => {
        const { isEdit } = this.state;
        const { editProduct, addProduct } = this.props;
        if (isEdit) {
            editProduct(this.handleOnSuccess);
        } else {
            addProduct(this.handleOnSuccess);
        }
    }

    productForm = () => {
        const { product: { product, errors } } = this.props;

        return (
            <form>
                <div className="row">
                    <div className="col-10">
                        <label>Nazwa produktu</label>
                        <input type="text" className="form-control" name="name" onChange={(e) => this.changeField("name", e)} defaultValue={product.name} placeholder="Wpisz nazwę produktu" />
                        {errors.name && <span className="text-danger">{errors.name}</span>}
                    </div>
                    <div className="col-2">
                        <label>Kategorie</label>
                        <CategoryTree content="productCategory" id="productCategory" onCheck={(checked) => this.props.changeField("categories", checked)} buttonText="Wybierz kategorie" checked={product.categories || []} />
                        {errors.categories && <span className="text-danger">{errors.categories}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>EAN</label>
                        <input type="text" className="form-control" name="ean" onChange={(e) => this.changeField("ean", e)} defaultValue={product.ean} placeholder="Wpisz EAN produktu" />
                        {errors.ean && <span className="text-danger">{errors.ean}</span>}
                    </div>
                    <div className="form-group col-4 mt-3">
                        <label>Typ</label>
                        <select className="form-control" onChange={(e) => this.changeField("type", e)} defaultValue={product.type || "0"} id="type" placeholder="Wybierz typ produktu">
                            {DictManager.getOptionsByType(1)}
                        </select>
                        {errors.type && <span className="text-danger">{errors.type}</span>}
                    </div>
                    <div className="form-group col-4 mt-3">
                        <label>Jednostka miary</label>
                        <select className="form-control" onChange={(e) => this.changeField("unit", e)} defaultValue={product.unit || "0"} id="unit" placeholder="Wybierz jednostkę miary produktu">
                            {DictManager.getOptionsByType(3)}
                        </select>
                        {errors.unit && <span className="text-danger">{errors.unit}</span>}
                    </div>

                    <div className="col-6 mt-3">
                        <label>Waga netto</label>
                        <input type="number" className="form-control" name="nettoWeight" onChange={(e) => this.changeField("nettoWeight", e)} defaultValue={product.nettoWeight} placeholder="Wpisz wagę" required min="0" />
                        {errors.nettoWeight && <span className="text-danger">{errors.nettoWeight}</span>}
                    </div>
                    <div className="col-6 mt-3">
                        <label>Tara</label>
                        <input type="number" className="form-control" name="tareWeight" onChange={(e) => this.changeField("tareWeight", e)} defaultValue={product.tareWeight} placeholder="Wpisz wagę" required min="0" />
                        {errors.tareWeight && <span className="text-danger">{errors.tareWeight}</span>}
                    </div>

                    <div className="col-6 mt-3">
                        <label>Ilość opakowań jednostkowych w kartonie zbiorczym [szt./karton]</label>
                        <input type="number" className="form-control" name="unitsInPackage" onChange={(e) => this.changeField("unitsInPackage", e)} defaultValue={product.unitsInPackage} placeholder="Wpisz ilość" required min="0" />
                        {errors.unitsInPackage && <span className="text-danger">{errors.unitsInPackage}</span>}
                    </div>
                    <div className="col-6 mt-3">
                        <label>Ilość kartonów zbiorczych na palecie euro [szt./paleta]</label>
                        <input type="number" className="form-control" name="packagesOnEuroPallet" onChange={(e) => this.changeField("packagesOnEuroPallet", e)} defaultValue={product.packagesOnEuroPallet} placeholder="Wpisz ilość" min="0" />
                        {errors.packagesOnEuroPallet && <span className="text-danger">{errors.packagesOnEuroPallet}</span>}
                    </div>

                    <div className="col-4 mt-3">
                        <label>PKWiU</label>
                        <input type="text" className="form-control" name="pkwiu" onChange={(e) => this.changeField("pkwiu", e)} defaultValue={product.pkwiu} placeholder="Wpisz PKWiU" />
                        {errors.pkwiu && <span className="text-danger">{errors.pkwiu}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>Kod produktu</label>
                        <input type="text" className="form-control" name="code" onChange={(e) => this.changeField("code", e)} defaultValue={product.code} placeholder="Wpisz kod" />
                        {errors.code && <span className="text-danger">{errors.code}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>Okres ważności (miesięcy)</label>
                        <input type="number" className="form-control" name="expiresIn" onChange={(e) => this.changeField("expiresIn", e)} defaultValue={product.expiresIn} placeholder="Wpisz okres ważności" required min="0" />
                        {errors.expiresIn && <span className="text-danger">{errors.expiresIn}</span>}
                    </div>

                    <div className="col-4 mt-3">
                        <label>Waga brutto pełnego kartonu [kg/karton]</label>
                        <input type="number" className="form-control" name="fullPackageWeight" onChange={(e) => this.changeField("fullPackageWeight", e)} defaultValue={product.fullPackageWeight} placeholder="Wpisz wagę" min="0" />
                        {errors.fullPackageWeight && <span className="text-danger">{errors.fullPackageWeight}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>Waga brutto jednej sztuki [kg/opakowanie]</label>
                        <input type="number" className="form-control" name="singleUnitWeight" onChange={(e) => this.changeField("singleUnitWeight", e)} defaultValue={product.singleUnitWeight} placeholder="Wpisz wagę" min="0" />
                        {errors.singleUnitWeight && <span className="text-danger">{errors.singleUnitWeight}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>Objętość całkowita [m3]</label>
                        <input type="number" className="form-control" name="totalVolume" onChange={(e) => this.changeField("totalVolume", e)} defaultValue={product.totalVolume} placeholder="Wpisz objętość" min="0" />
                        {errors.totalVolume && <span className="text-danger">{errors.totalVolume}</span>}
                    </div>

                    <div className="col-4 mt-3">
                        <label>Szerokość [m]</label>
                        <input type="number" className="form-control" name="width" onChange={(e) => this.changeField("width", e)} defaultValue={product.width} placeholder="Wpisz szerokość" min="0" />
                        {errors.width && <span className="text-danger">{errors.width}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>Długość [m]</label>
                        <input type="number" className="form-control" name="length" onChange={(e) => this.changeField("length", e)} defaultValue={product.length} placeholder="Wpisz długość" min="0" />
                        {errors.length && <span className="text-danger">{errors.length}</span>}
                    </div>
                    <div className="col-4 mt-3">
                        <label>Wysokość [m]</label>
                        <input type="number" className="form-control" name="height" onChange={(e) => this.changeField("height", e)} defaultValue={product.height} placeholder="Wpisz wysokość" min="0" />
                        {errors.height && <span className="text-danger">{errors.height}</span>}
                    </div>

                    <div className="col-12 mt-3">
                        <label>Opis</label>
                        <textarea className="form-control" name="description" onChange={(e) => this.changeField("description", e)} defaultValue={product.description} placeholder="Wpisz opis" />
                        {errors.description && <span className="text-danger">{errors.description}</span>}
                    </div>
                </div>
                <hr />
                <h6 className="text-center">Oznaczenia produktu</h6>
                <div className="row mt-3">
                    <div className="col-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.active || false} onChange={(e) => this.changeCheckbox("active", e)} />
                            <label className="form-check-label">
                                Aktywny
                            </label>
                        </div>
                        {errors.active && <span className="text-danger">{errors.active}</span>}
                    </div>
                    <div className="col-2" title="Produkt oznaczony jako gratis nie będzie skanowany podczas realizacji dokumentu">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.gratis || false} onChange={(e) => this.changeCheckbox("gratis", e)} />
                            <label className="form-check-label">
                                Gratis
                            </label>
                        </div>
                        {errors.gratis && <span className="text-danger">{errors.gratis}</span>}
                    </div>
                    <div className="col-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.PAO || false} onChange={(e) => this.changeCheckbox("PAO", e)} />
                            <label className="form-check-label">
                                PAO
                            </label>
                        </div>
                        {errors.PAO && <span className="text-danger">{errors.PAO}</span>}
                    </div>
                    <div className="col-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.GMP || false} onChange={(e) => this.changeCheckbox("GMP", e)} />
                            <label className="form-check-label">
                                GMP
                            </label>
                        </div>
                        {errors.GMP && <span className="text-danger">{errors.GMP}</span>}
                    </div>
                    <div className="col-2" title="Produkt oznaczony jako usługa będzie pomijany podczas importu">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.service || false} onChange={(e) => this.changeCheckbox("service", e)} />
                            <label className="form-check-label">
                                Usługa
                            </label>
                        </div>
                        {errors.service && <span className="text-danger">{errors.service}</span>}
                    </div>
                    <div className="col-2">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.material || false} onChange={(e) => this.changeCheckbox("material", e)} />
                            <label className="form-check-label">
                                Surowiec
                            </label>
                        </div>
                        {errors.material && <span className="text-danger">{errors.material}</span>}
                    </div>
                    <div className="col-2 mt-3" title="Produkt nie ma możliwości wpisania numeru serii przy generowaniu kodów">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.noSeries || false} onChange={(e) => this.changeCheckbox("noSeries", e)} />
                            <label className="form-check-label">
                                Bez numeru serii
                            </label>
                        </div>
                        {errors.noSeries && <span className="text-danger">{errors.noSeries}</span>}
                    </div>
                    <div className="col-2 mt-3" title="Produkt nie wymaga terminu ważności w dokumencie podczas importu. Automatycznie ustawia się maksymalna data ważności jeśli nie jest określona.">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.indefiniteExpiration || false} onChange={(e) => this.changeCheckbox("indefiniteExpiration", e)} />
                            <label className="form-check-label">
                                Nieokreślony termin ważności
                            </label>
                        </div>
                        {errors.indefiniteExpiration && <span className="text-danger">{errors.indefiniteExpiration}</span>}
                    </div>
                    <div className="col-2 mt-3" title="Produkt jest etykietą lub doklejką.">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" checked={product.isLabel || false} onChange={(e) => this.changeCheckbox("isLabel", e)} />
                            <label className="form-check-label">
                                Etykieta / doklejka
                            </label>
                        </div>
                        {errors.isLabel && <span className="text-danger">{errors.isLabel}</span>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mt-5 mb-5 text-center">
                        <button type="button" className="btn btn-primary" onClick={() => { this.saveProduct() }}>Zapisz</button>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to="/products" />;
        }
        const navbar = this.productsNavbar();
        const form = this.productForm();

        return (
            <div>
                <div className="d-flex flex-column container product-container">
                    {navbar}
                    {form}
                </div>
            </div>

        )
    }

    componentWillUnmount = () => {
        const { clearProduct, clearErrors } = this.props;
        clearProduct();
        clearErrors();
    }
}