import React, { Component } from 'react';

import BasicInput from '../../../application/components/basicInput';
import ComponentNavbar from '../../../application/components/componentNavbar';


export default class WarehousePositionComponent extends Component {

    state = {
        warehouseId: null,
        warehouseCode: '',
        column: '',
        row: '',
        type: null,
        warehousePositionTypes: null
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.warehousePositionTypes && nextProps.warehousePositionTypes.length > 0) {
            this.setState({ warehousePositionTypes: nextProps.warehousePositionTypes })
        }
    }

    componentDidMount() {
        const { getWarehousePositionType } = this.props;

        if (this.props.location && this.props.location.state) {
            if (this.props.location.state.warehouseCode) this.setState({ warehouseCode: this.props.location.state.warehouseCode })
            if (this.props.location.state.warehouseId) {
                const { setWarehouseId } = this.props;
                const warehouseId = this.props.location.state.warehouseId;

                setWarehouseId(warehouseId)
                this.setState({ warehouseId: warehouseId })
            }
        }

        getWarehousePositionType()
    }

    handleSaveWarehousePosition = () => {
        const { warehouseId, column, row, type } = this.state;
        const { saveWarehousePosition } = this.props;
        const data = {
            code: column !== '' && row !== '' ? column.toUpperCase() + '.' + row : null,
            warehouse: warehouseId,
            type: type
        };

        saveWarehousePosition(data);
    }

    onInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;

        this.setState({ [name]: value })
    }

    renderErrors = (type) => {
        const { errors } = this.props;

        switch (type) {
            case 'code':
                if (errors && errors.code) return <div><span className="text-danger"><b>{errors.code}</b></span><br /></div>
                return null
            case 'column':
                if (errors && errors.column) return <div><span className="text-danger">{errors.column}</span><br /></div>
                return null
            case 'row':
                if (errors && errors.row) return <div><span className="text-danger">{errors.row}</span><br /></div>
                return null
            case 'type':
                if (errors && errors.type) return <div><span className="text-danger">{errors.type}</span><br /></div>
                return null
            default: return null
        }
    }

    renderPositionTypes = () => {
        const { warehousePositionTypes } = this.state;

        if (warehousePositionTypes && warehousePositionTypes.values) {

            return warehousePositionTypes.map(warehousePositionType => {
                if (warehousePositionType.values) {
                    let selectOptions = warehousePositionType.values.map((option, index) => {
                        return <option value={option.value} key={index + 1}>{option.name}</option>
                    });

                    return [<option key={0}>Wybierz opcje....</option>].concat(selectOptions);
                }

                return null
            })

        }

    }

    onChangeType = (e) => {
        let value = e.target.value;
        if (value === 'Wybierz opcje...') {
            value = null;
        }
        this.setState({ type: value })
    }

    render() {

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title="Dodaj pozycję magazynową"
                />
                <div className="row mt-5">
                    <di className="col-6">
                        <BasicInput
                            className="form-group"
                            type="text"
                            id="warehouseCode"
                            label="Kod magayznu"
                            onChange={this.onInputChange}
                            value={this.state.warehouseCode}
                            disabled={true} />
                        {this.renderErrors('warehouseCode')}
                    </di>
                    <di className="col-6">
                        <label>Typ pozycji</label>
                        <select
                            className="form-control"
                            onChange={this.onChangeType}>
                            {this.renderPositionTypes()}
                        </select>
                        {this.renderErrors('type')}
                        {this.renderErrors('code')}
                    </di>
                    <di className="col-6">
                        <BasicInput
                            className="form-group"
                            type="text"
                            id="column"
                            label="Kolumna"
                            placeholder="Wpisz kolumnę..."
                            onChange={this.onInputChange}
                            value={this.state.column} />
                        {this.renderErrors('column')}
                    </di>
                    <di className="col-6">
                        <BasicInput
                            className="form-group"
                            type="text"
                            id="row"
                            label="Rząd "
                            placeholder="Wpisz rząd..."
                            onChange={this.onInputChange}
                            value={this.state.row} />
                        {this.renderErrors('row')}
                    </di>
                </div>
                <div className="row text-center mt-5 mb-5">
                    <div className="col-md-12">
                        <button onClick={this.handleSaveWarehousePosition} type="button" className="btn btn-primary">Zapisz</button>
                    </div>
                </div>
            </div>
        )

    }

    componentWillUnmount() {
        const { setIsUpdated, clearErrors } = this.props;
        setIsUpdated(false);
        clearErrors()
    }

}