import React from 'react';
import Dropzone from 'react-dropzone';

// import imageCompression from 'browser-image-compression';

export default class FileUploader extends React.Component {

    processFiles = (acceptedFiles, props) => {
        acceptedFiles.forEach((file, i) => {
            props.isLoading(true);

            props.uploadDocument(file);

            props.isLoading(false);
        })
    }

    render() {
        return (
            <Dropzone onDrop={(acceptedFiles) => {
                this.processFiles(acceptedFiles, this.props);
            }}
            >
                {({ getRootProps, getInputProps }) => (
                    <section style={this.props.filedropperStyle}>
                        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "inherit", width: "100%" }} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <p >Przeciągnij plik i upuść bądź kliknij by dodać pliki</p>
                        </div>
                    </section>
                )}
            </Dropzone>
        )
    }
}
