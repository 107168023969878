import Api from '../../services/api';
import { toast } from 'react-toastify';

import { isLoading } from '../../application/actions/application.actions'

const namespace = 'USERS';
export const SET_USERS = `SET_USERS_${namespace}`;
export const CLEAR_USERS = `CLEAR_USERS_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setUsers = (users, total) => (dispatch) => {
    dispatch({
        type: SET_USERS,
        users: users,
        total: total
    });
}

export const clearUsers = () => (dispatch) => {
    dispatch({
        type: CLEAR_USERS
    });
}

export const getUsers = () => async (dispatch, getState) => {
    const { users: { filters, offset, limit } } = getState();
    dispatch(isLoading(true));

    let params = {
        "sort[active]": -1,
        "sort[name]": 1,
        "limit": limit,
        "offset": offset,
        "select": "name,failedLogins,active"
    };
    if (filters.query) {
        params["name"] = filters.query;
    }
    if (filters.role) {
        params["role"] = filters.role._id;
    }
    if (filters.warehouse) {
        params["warehouse"] = filters.warehouse.code;
    }
    if (filters.active) {
        params["active"] = filters.active;
    }

    let res = await Api.get("/application/user", params);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setUsers(res.documents, res.total));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}
