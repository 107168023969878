import React from 'react';
import { connect } from 'react-redux';
import {
    clearDocument,
    getDocument,
    setFieldValue,
    saveAdvancedEdit,
    getSuggestedSeries,
    clearSuggestedSeries,
    changeReservationAmount,
    saveManualCompletion
} from '../actions/document.actions';
import DocumentCompletion from '../components/documentCompletion.component';
import '../documents.css';

class DocumentCompletionContainer extends React.Component {

    render() {
        return (
            <div>
                <DocumentCompletion
                    {...this.props}
                />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isAdmin: store.auth.isAdmin,
        document: store.document
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDocument: (id, withReservations) => {
            dispatch(getDocument(id, withReservations))
        },
        clearDocument: () => {
            dispatch(clearDocument())
        },
        setFieldValue: (name, value) => {
            dispatch(setFieldValue(name, value));
        },
        saveAdvancedEdit: (history) => {
            dispatch(saveAdvancedEdit(history));
        },
        getSuggestedSeries: (warehouseCode, productId) => {
            dispatch(getSuggestedSeries(warehouseCode, productId))
        },
        clearSuggestedSeries: () => {
            dispatch(clearSuggestedSeries())
        },
        changeReservationAmount: (product, suggestedSeries, amount) => {
            dispatch(changeReservationAmount(product, suggestedSeries, amount));
        },
        saveManualCompletion: (history) => {
            dispatch(saveManualCompletion(history));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCompletionContainer);