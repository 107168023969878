import Api from '../../services/api';
import { toast } from 'react-toastify';
import _ from "lodash";

const namespace = 'CATEGORYTREE';
export const SET_CATEGORIES = `SET_CATEGORIES_${namespace}`;

export const setCategories = (documents, id) => (dispatch, getState) => {
    const { categoryTree: { categories } } = getState();

    let newCategories = Object.assign({}, categories);
    newCategories[id] = documents;
    dispatch({
        type: SET_CATEGORIES,
        categories: newCategories
    });
}

export const clearCategoryTree = (id) => (dispatch, getState) => {
    const { categoryTree: { categories } } = getState();

    let newCategories = Object.assign({}, categories);

    if (newCategories[id]) {
        delete newCategories[id];
        dispatch({
            type: SET_CATEGORIES,
            categories: newCategories
        });
    }
}

const getUrl = (content) => {
    switch (content) {
        case 'productCategory': {
            return "/product";
        }
        default: {
            return null;
        }
    }
}

export const getCategoryTree = (content, id) => async (dispatch) => {
    let url = getUrl(content);
    let res = await Api.get(`${url}/getCategoryTree`);

    if (res && res.success) {
        dispatch(setCategories(res.document, id));
    } else {
        toast('Wystąpił błąd');
    }
}


export const saveCategory = (content, id, data) => async (dispatch) => {
    let url = getUrl(content);
    _.each(data, (val, key) => {
        if (_.isNil(val)) {
            delete data[key];
        }
    })

    _.each(data, (val, key) => {
        if (_.isNil(val)) {
            delete data[key];
        }
    });

    let res = null;
    if (data._id) {
        res = await Api.put(`${url}/category/${data._id}`, data);
    } else {
        res = await Api.post(`${url}/category`, data);
    }

    if (res && res.success) {
        dispatch(getCategoryTree(content, id));
        toast('Zapisano');
        dispatch(getCategoryTree(content, id));
    } else {
        toast('Wystąpił błąd');
    }
}

export const deleteCategory = (content, categoryId, id) => async (dispatch) => {
    let url = getUrl(content);

    let res = await Api.delete(`${url}/category/${categoryId}`);
    if (res && res.success) {
        dispatch(getCategoryTree(content, id));
        toast('Usunięto');
    } else {
        toast('Wystąpił błąd');
    }
}





