import React from 'react';
import { connect } from 'react-redux';
import { isLoading } from "../../application/actions/application.actions";
import { uploadDocument } from '../actions/enovaImport.actions';
import EnovaImport from '../components/enovaImport.component';
import '../documents.css';

class EnovaImportContainer extends React.Component {
    render() {
        return (
            <div>
                <EnovaImport {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        documents: store.documents,
        enovaImport: store.enovaImport
    }
}

function mapDispatchToProps(dispatch) {
    return {
        isLoading: (loading) => {
            dispatch(isLoading(loading));
        },
        uploadDocument: (file) => {
            dispatch(uploadDocument(file))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnovaImportContainer);