import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import DeleteConfirmationModal from '../../application/components/deleteConfirmationModal';
import Pagination from "../../application/components/pagination";
import SingleDetail from '../../application/components/singleDetail';
import Acl from '../../services/acl.service';
import DictManager from "../../services/dict.service";

export default class ProductDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            modalVisibility: false,
            activeTab: "amount-tab",
            historyPage: 1
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        this.props.getProduct(id);
    }

    componentWillUnmount = () => {
        this.props.clearProduct();
    }

    handleOpenDeleteModal = () => {
        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        });
    }

    renderDeleteModal = () => {
        return (
            <DeleteConfirmationModal
                title="Usuwanie produktu"
                msg="Czy na pewno chcesz usunąć ten produkt?"
                handleCloseModal={this.handleCloseDeleteModal}
                confirmAction={this.handleDeleteProduct}
            />);
    }

    handleDeleteProduct = () => {
        const { history, match, deleteProduct } = this.props;
        let id = _.get(match, 'params.id');
        if (id) {
            deleteProduct(id, history);
        }
        this.handleCloseDeleteModal();
    }

    navbarButton = () => {
        const { isAdmin } = this.props.auth;
        const { product } = this.props.product;
        let canDelete = Acl.isAllowedAction("product", "product", "delete", isAdmin);
        let canEdit = Acl.isAllowedAction('product', 'product', 'update', isAdmin);
        if (product) {
            return (
                <div className="btn-group">
                    {canEdit &&
                        <div>
                            <Link to={`/products/product/${product._id}`}>
                                <button
                                    style={{ width: '150px' }}
                                    type='button'
                                    className='btn btn-outline-primary'> Edytuj </button>
                            </Link>
                        </div>}
                    {canDelete && <button className="btn btn-outline-danger" onClick={() => this.handleOpenDeleteModal()}>Usuń</button>}
                </div>
            )
        }

    }

    productDetails = () => {
        if (this.props.product.product) {
            const { product: { name, unitsInPackage, fullPackageWeight, singleUnitWeight, width, length, height, totalVolume, packagesOnEuroPallet, ean, type, pkwiu, code, expiresIn, PAO, gratis, active, GMP, service, unit, material, noSeries, categories, isLabel } } = this.props.product;

            return (
                <div className="row product-details-container">
                    <div className="col">
                        <SingleDetail
                            label={'Nazwa produktu'}
                            value={name}
                        />
                        <SingleDetail
                            label={'EAN'}
                            value={ean}
                        />
                        <SingleDetail
                            label={'Typ'}
                            value={DictManager.getNameByValue(1, type)}
                        />
                        <SingleDetail
                            label={'PKWiU'}
                            value={pkwiu}
                        />
                        <SingleDetail
                            label={'Kod'}
                            value={code}
                        />
                        <SingleDetail
                            label={'Aktywny'}
                            value={active ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Jednostka miary'}
                            value={unit}
                        />
                        <SingleDetail
                            label={'Kategorie'}
                            value={!_.isEmpty(categories) ? _.join(categories.map((c) => c.breadcrumb), ", ") : "-"}
                        />
                    </div>

                    <div style={{ backgroundColor: 'gray', width: '1px', }} />

                    <div className="col">
                        <SingleDetail
                            label={'Ilość opakowań jednostkowych w kartonie zbiorczym [szt./karton]'}
                            value={unitsInPackage}
                        />
                        <SingleDetail
                            label={'Ilość kartonów zbiorczych na palecie euro [szt./paleta]'}
                            value={packagesOnEuroPallet}
                        />
                        <SingleDetail
                            label={'Waga brutto pełnego kartonu [kg/karton]'}
                            value={fullPackageWeight}
                        />
                        <SingleDetail
                            label={'Waga brutto jednej sztuki [kg/opakowanie]'}
                            value={singleUnitWeight}
                        />
                        <SingleDetail
                            label={'Objętość całkowita [m3]'}
                            value={totalVolume}
                        />
                        <SingleDetail
                            label={'Termin ważności'}
                            value={expiresIn}
                        />
                    </div>

                    <div style={{ backgroundColor: 'gray', width: '1px' }} />

                    <div className="col">
                        <SingleDetail
                            label={'Szerokość [m]'}
                            value={width}
                        />
                        <SingleDetail
                            label={'Długość [m]'}
                            value={length}
                        />
                        <SingleDetail
                            label={'Wysokość [m]'}
                            value={height}
                        />
                        <SingleDetail
                            label={'PAO'}
                            value={PAO ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Gratis'}
                            value={gratis ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'GMP'}
                            value={GMP ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Usługa'}
                            value={service ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Surowiec'}
                            value={material ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Bez numeru serii'}
                            value={noSeries ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Etykieta / doklejka'}
                            value={isLabel ? "Tak" : "Nie"}
                        />
                    </div>
                </div>
            )
        }

    }

    getExpirationDate = (series, expiresIn) => {
        try {
            if (!series || !expiresIn || isNaN(expiresIn)) {
                return "-"
            }
            let size = series.length;
            let day = parseInt(series.substr(size - 2, 2));
            let month = series.substr(size - 4, 2);
            let year = 2000 + parseInt(series.substr(size - 6, 2)) - parseInt(month);
            return moment(`${year}-${month}-${day}`).add(parseInt(expiresIn), 'months').format("DD.MM.YYYY");
        } catch (err) {
            return "-";
        }
    }

    productPositionRows = (positions) => {
        return positions.map((pos, i) => {
            const { position, warehouse, amount, seriesNr, expirationDate, unit } = pos;
            return (
                <tr key={i}>
                    <td>{(seriesNr) ? seriesNr : '-'}</td>
                    <td>{(warehouse) ? warehouse : '-'}</td>
                    <td>{(position) ? position : '-'}</td>
                    <td>{(amount) ? amount : '-'}</td>
                    <td>{(unit) ? unit : '-'}</td>
                    <td>{(expirationDate) ? moment(expirationDate).format("DD.MM.YYYY") : '-'}</td>
                </tr>
            )
        });
    }

    getProductsSum = (positions) => {
        let sum = {};
        let jm = null;
        _.each(positions, (position) => {
            if (!jm) {
                jm = position.unit;
            }
            if (!sum[position.warehouse]) {
                sum[position.warehouse] = position.amount;
            } else {
                sum[position.warehouse] += position.amount;
            }
        });
        return _.keys(sum).map((warehouse, i) => {
            return (
                <tr key={`sum-${i}`}>
                    <td>{warehouse}</td>
                    <td>{_.round(sum[warehouse], 5)}</td>
                    <td>{jm ? jm : "-"}</td>
                </tr>
            )
        });
    }

    productPositions = () => {
        const product = this.props.product.product;
        if (product && product.positions && product.positions.length > 0) {
            const { positions } = this.props.product.product;
            let sum = this.getProductsSum(positions);
            return (
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Seria</th>
                                    <th scope="col">Magazyn</th>
                                    <th scope="col">Pozycja Magazynowa</th>
                                    <th scope="col">Ilość</th>
                                    <th scope="col">Jm</th>
                                    <th scope="col">Data ważności</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.productPositionRows(positions)}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12 mt-5">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Magazyn</th>
                                    <th scope="col">Suma</th>
                                    <th scope="col">Jm</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sum}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="mt-5 mb-5 text-center">
                    <p> Brak pozycji magazynowych</p>
                </div>
            )
        }
    }

    setActiveTab = (name) => {
        const { activeTab } = this.state;
        if (name !== activeTab) {
            document.getElementById(activeTab).classList.remove("active");
            document.getElementById(name).classList.add("active");
            this.setState({ activeTab: name });

        }
    }

    getDetailsTabs = () => {
        const { auth: { isAdmin } } = this.props;
        let hasHistoryPermission = Acl.isAllowedAction("warehouse", "positionHistory", "get", isAdmin);
        let hasReservationsPermission = Acl.isAllowedAction("document", "reservation", "get", isAdmin);
        return (
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <span className="nav-link active" id="amount-tab" onClick={() => this.setActiveTab("amount-tab")}>Stan magazynowy</span>
                </li>
                {hasHistoryPermission && <li className="nav-item">
                    <span className="nav-link" id="history-tab" onClick={() => this.loadProductHistory()}>Historia</span>
                </li>}
                {hasReservationsPermission && <li className="nav-item">
                    <span className="nav-link" id="reservations-tab" onClick={() => this.loadProductReservations()}>Rezerwacje</span>
                </li>}
            </ul>
        )
    }

    loadProductReservations = () => {
        const { loadProductReservations } = this.props;
        loadProductReservations();
        this.setActiveTab('reservations-tab');
    }

    loadProductHistory = () => {
        const { getProductHistory } = this.props;
        getProductHistory();
        this.setActiveTab('history-tab');
    }

    getTabContent = () => {
        const { activeTab } = this.state;

        switch (activeTab) {
            case "amount-tab": {
                return (
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center">Stan magazynowy produktu</h3>
                            </div>
                        </div>
                        <br />
                        {this.productPositions()}
                    </div>
                );
            }
            case "history-tab": {
                return (<div>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-center">Historia produktu</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderProductHistory()}
                </div >);
            }
            case "reservations-tab": {
                return (<div>
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-center">Rezerwacje</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderProductReservations()}
                </div >);
            }
            default: {
                return null;
            }
        }
    }

    setPage = (page) => {
        this.setState({ historyPage: page });
    }

    renderProductReservations = () => {
        const { product: { reservations } } = this.props;
        if (_.isEmpty(reservations)) {
            return <p className="mt-5 mb-5 text-center">Brak rezerwacji</p>;
        } else {
            let rows = reservations.map((reservation, index) => {
                return (<tr key={`reservation-${index}`}>
                    <td>{moment(reservation.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                    <td><Link className="text-dark" to={`/documents/details/${reservation.docId}`}>{reservation.documentNumber}</Link></td>
                    <td>{reservation.series.positionCode}</td>
                    <td>{reservation.series.seriesNr}</td>
                    <td>{Math.abs(reservation.series.amount)}</td>
                </tr>);
            });
            return (
                <div className="row">
                    <div className="col-12">
                        <table className="table">
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Utworzona</th>
                                    <th scope="col">Dokument</th>
                                    <th scope="col">Pozycja Magazynowa</th>
                                    <th scope="col">Seria</th>
                                    <th scope="col">Ilość</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        }
    }

    renderHistoryPagination = () => {
        const { product: { history: { offset, total, limit } }, setHistoryOffset, getProductHistory } = this.props;
        const { historyPage } = this.state;

        return (
            <Pagination
                getDocuments={getProductHistory}
                changeOffset={setHistoryOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={historyPage}
            />
        )
    }

    renderProductHistory = () => {
        const { product: { history: { items }, product: { _id } } } = this.props;
        const searchBar = this.renderSearchBar();

        if (_.isArray(items) && items.length > 0 && _id) {
            const pagination = this.renderHistoryPagination();

            let cards = items.map((history, index) => {

                let products = _.filter(history.products, (p) => p.productId === _id).map((product, i) => {
                    return (
                        <tr key={`product${i}`}>
                            <td>{i + 1}</td>
                            <td><Link className="text-dark" to={`/warehouses/position/${history.position._id}`}>{history.positionCode}</Link></td>
                            <td>{product.seriesNr}</td>
                            <td>{product.amountBefore}</td>
                            <td>{product.amountAfter}</td>
                            <td>{_.round(Math.abs(product.amountBefore - product.amountAfter), 5)}</td>
                        </tr>
                    )
                });
                let isInventoryHistory = _.includes(history.document.documentNumber, "/IN/");
                let isCorrection = !!history.correction;
                let isUndone = !!history.undone;
                let hideLink = isCorrection || isUndone;
                return (
                    <li key={index} className={`list-group-item list-group-item-action mb-1 ${isCorrection && 'bg-correction'} ${isUndone && 'bg-undone'}`}>
                        <div className="row pb-2">
                            <div className="col-8">
                                {hideLink &&
                                    <b>
                                        {history.document.documentNumber}
                                        {isCorrection && <span className="text-success"> DOKUMENT WYCOFANY</span>}
                                        {isUndone && <span className="text-danger"> DOKUMENT WYCOFANY</span>}
                                    </b>}
                                {isInventoryHistory ?
                                    !hideLink && <Link to={`/inventories/details/${history.document.documentId}`}><b>{history.document.documentNumber}</b></Link> :
                                    !hideLink && <Link to={`/documents/details/${history.document.documentId}`}><b>{history.document.documentNumber}</b></Link>}
                            </div>
                            <div className="col-4 text-right">
                                <b>{moment(history.createdAt).format("DD.MM.YYYY HH:mm")}</b>
                            </div>
                        </div>
                        <table className="table" style={{ width: "100%" }}>
                            <thead className="thead-dark">
                                <tr>
                                    <th scope="col">Lp.</th>
                                    <th scope="col">Pozycja</th>
                                    <th scope="col">Numer serii</th>
                                    <th scope="col">Ilość przed</th>
                                    <th scope="col">Ilość po</th>
                                    <th scope="col">Różnica</th>
                                </tr>
                            </thead>
                            <tbody >
                                {products}
                            </tbody>
                        </table>
                    </li>
                );
            });
            return (<div className="d-flex flex-column">
                {searchBar}
                {pagination}
                <div>
                    <ul className="p-0">{cards}</ul>
                </div>
                {pagination}
            </div>);
        } else {
            return (
                <div className="row text-center mb-5">
                    <div className="col-12">
                        {searchBar}
                    </div>
                    <div className="col-12">
                        <p className="mt-3">Brak wpisów w historii</p>
                    </div>
                </div>
            )
        }
    }

    changeFilter = (e, name) => {
        const { setHistoryFilters, getProductHistory } = this.props;
        let value = e.target.value;

        if (name === "dateFrom") {
            if (value === "") {
                value = moment().startOf('month').toISOString();
            } else {
                value = moment(value).startOf('day').toISOString();
            }
        }
        if (name === "dateTo") {
            if (value === "") {
                value = moment().endOf('day').toISOString();
            } else {
                value = moment(value).endOf('day').toISOString();
            }
        }

        setHistoryFilters(name, value);
        this.setState({ historyPage: 1 });
        getProductHistory();
    }

    resetFilters = () => {
        const { resetHistoryFilters, getProductHistory } = this.props;
        resetHistoryFilters();
        this.setState({ historyPage: 1 });
        getProductHistory();
    }

    renderSearchBar = () => {
        const { product: { history: { filters } } } = this.props;

        return (
            <div>
                <div className="row mb-5">
                    <div className="col-md-2">
                        <label>Numer serii</label>
                        <input type="text" className="form-control" placeholder="Wpisz numer serii" value={filters.seriesNr} onChange={(e) => this.changeFilter(e, 'seriesNr')} />
                    </div>
                    <div className="col-md-2">
                        <label>Numer dokumentu</label>
                        <input type="text" className="form-control" placeholder="Wpisz numer dokumentu" value={filters.documentNumber} onChange={(e) => this.changeFilter(e, 'documentNumber')} />
                    </div>
                    <div className="col-md-2">
                        <label>Numer pozycji</label>
                        <input type="text" className="form-control" placeholder="Wpisz numer pozycji" value={filters.positionCode} onChange={(e) => this.changeFilter(e, 'positionCode')} />
                    </div>
                    <div className="col-md-2">
                        <label>Data utworzenia (od)</label>
                        <input type="date" className="form-control" value={moment(filters.dateFrom).format("YYYY-MM-DD")} onChange={(e) => this.changeFilter(e, 'dateFrom')} />
                    </div>
                    <div className="col-md-2">
                        <label>Data utworzenia (do)</label>
                        <input type="date" className="form-control" value={moment(filters.dateTo).format("YYYY-MM-DD")} onChange={(e) => this.changeFilter(e, 'dateTo')} />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>

            </div>

        )
    }

    render() {
        const tabs = this.getDetailsTabs();
        const tabContent = this.getTabContent();
        const deleteModal = this.state.showDeleteModal ? this.renderDeleteModal() : null;
        return (
            <div className="d-flex flex-column container product-container">
                {deleteModal}
                <ComponentNavbar
                    title="Szczegóły produktu"
                    button={this.navbarButton()}
                />
                {this.productDetails()}
                <br /> <br /> <br />
                <div className="row user-select-none mb-3">
                    <div className="col-12">
                        {tabs}
                    </div>
                </div>
                {tabContent}
            </div>
        )
    }

    componentWillUnmount() {
        const { resetHistoryFilters, clearProductReservations } = this.props;
        resetHistoryFilters();
        clearProductReservations();
    }
}