import FileSaver from "file-saver";
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';
const namespace = 'INVENTORY';
export const SET_INVENTORY = `SET_INVENTORY_${namespace}`;
export const CLEAR_INVENTORY = `CLEAR_INVENTORY_${namespace}`;
export const SET_SAVED = `SET_SAVED_${namespace}`;
export const SET_ERRORS = `SET_ERRORS_${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS_${namespace}`;
export const SET_INVENTORY_HISTORY = `SET_INVENTORY_HISTORY_${namespace}`;
export const CLEAR_INVENTORY_HISTORY = `CLEAR_INVENTORY_HISTORY_${namespace}`;
export const SET_HISTORY_OFFSET = `SET_HISTORY_OFFSET_${namespace}`;
export const SET_HISTORY_FILTERS = `SET_HISTORY_FILTERS_${namespace}`;
export const RESET_HISTORY_FILTERS = `RESET_HISTORY_FILTERS_${namespace}`;

export const setInventory = (inventory) => (dispatch) => {
    dispatch({
        type: SET_INVENTORY,
        inventory: inventory
    });
}

export const clearInventory = () => (dispatch) => {
    dispatch({
        type: CLEAR_INVENTORY
    });
}

export const getInventory = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.get(`/warehouse/inventory/${id}`, { "getStatistics": true });

    dispatch(isLoading(false));


    if (res && res.success) {
        dispatch(setInventory(res.document));
        dispatch(getInventoryHistory());
    } else {
        toast('Wystąpił błąd');
    }
}

export const deleteInventory = () => async (dispatch, getState) => {
    const { inventory: { inventory } } = getState();

    dispatch(isLoading(true));

    let res = await Api.delete(`/warehouse/inventory/${inventory._id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Usunięto');
        dispatch({
            type: SET_SAVED
        });
    } else {
        toast('Wystąpił błąd');
    }
}

export const saveInventory = () => async (dispatch, getState) => {
    const { inventory: { inventory } } = getState();
    dispatch(isLoading(true));
    dispatch(clearErrors());

    let data = Object.assign({}, inventory);

    if (data.role && _.isObject(data.role)) {
        data.role = data.role._id;
    }

    _.each(data, (value, key) => {
        if (_.isNil(value)) {
            delete data[key];
        }
    })


    let res = null;

    if (inventory._id) {
        res = await Api.put(`/warehouse/inventory/${inventory._id}`, data);
    } else {
        res = await Api.post("/warehouse/inventory", data);
    }
    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Zapisano');
        dispatch({
            type: SET_SAVED
        });
    } else {
        dispatch(setErrors(res.err || {}));
        toast('Wystąpił błąd');
    }
}

export const changeField = (name, value) => (dispatch, getState) => {
    const { inventory: { inventory } } = getState();

    let newInventory = Object.assign({}, inventory);
    newInventory[name] = value;
    dispatch(setInventory(newInventory));
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}

export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}

export const closeInventory = () => async (dispatch, getState) => {
    const { inventory: { inventory } } = getState();

    dispatch(isLoading(true));

    let res = await Api.put(`/warehouse/inventory/${inventory._id}`, { completed: true, completedAt: new Date() });

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Zapisano');
        let newInventory = JSON.parse(JSON.stringify(inventory));
        newInventory.completed = true;
        dispatch(setInventory(newInventory));
    } else {
        dispatch(setErrors(res.err || {}));
        toast('Wystąpił błąd');
    }
}

export const resetInventoryPosition = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.put(`/warehouse/inventory/resetInventoryPosition/${id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(getInventoryHistory());
        toast('Usunięto zmiany na pozycji');
    } else {
        dispatch(setErrors(res.err || {}));
        toast('Wystąpił błąd');
    }
}

export const generateReport = () => async (dispatch, getState) => {
    try {
        const { inventory: { inventory } } = getState();

        dispatch(isLoading(true));

        let res = await Api.get(`/warehouse/inventory/generateReport/${inventory._id}`, {}, "text");

        dispatch(isLoading(false));

        if (res) {
            var blob = new Blob([res], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            FileSaver.saveAs(blob, "Raport.xlsx");
            toast('Pobrano raport');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const allowInventoryEdit = (positionCode) => async (dispatch) => {
    try {
        dispatch(isLoading(true));

        let res = await Api.put(`/warehouse/inventory/allowInventoryEdit/${positionCode}`);

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch(getInventoryHistory());
            toast('Zapisano zmiany');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const getInventoryHistory = () => async (dispatch, getState) => {
    try {
        const { inventory: { history: { filters, limit, offset }, inventory } } = getState();

        dispatch(isLoading(true));
        let params = {
            "sort[createdAt]": -1,
            "limit": limit,
            "offset": offset,
            "document.documentId": inventory._id
        }

        if (filters.positionCode) {
            params["positionCode"] = filters.positionCode;
        }

        if (filters.seriesNr) {
            params["products.seriesNr"] = filters.seriesNr;
        }


        if (filters.product) {
            params["products.name"] = filters.product.name;
        }

        if (filters.user) {
            params["creator"] = filters.user._id;
        }

        let res = await Api.get("/warehouse/positionHistory", params);

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch(setInventoryHistory(res.documents, res.total));
        } else {
            toast('Wystąpił błąd');
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setInventoryHistory = (items, total) => (dispatch) => {
    dispatch({
        type: SET_INVENTORY_HISTORY,
        items: items,
        total: total
    });
}

export const clearInventoryHistory = () => (dispatch) => {
    dispatch({
        type: CLEAR_INVENTORY_HISTORY
    });
}

export const setHistoryOffset = (offset) => (dispatch) => {
    dispatch({
        type: SET_HISTORY_OFFSET,
        offset: offset
    });
}

export const setHistoryFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_HISTORY_FILTERS,
        filter,
        value
    });
}

export const resetHistoryFilters = () => dispatch => {
    dispatch({
        type: RESET_HISTORY_FILTERS
    });
}