import moment from "moment";
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import AclService from "../../../services/acl.service";
import GeneratorNewPrintComponent from '../generator.newPrint.component';
import './generator.newRecord.css';


class Code extends Component {

    state = {
        expirationDate: null,
        unitsInPackage: null,
        nettoWeight: null,
    }

    renderNettoWeight = () => {
        const { nettoWeight } = this.state;
        const { code } = this.props;
        if (nettoWeight) return `<p>WAGA NETTO : <b>${nettoWeight}</b></p>`;
        else if (code.nettoWeight) return `<p>WAGA NETTO : <b>${code.nettoWeight}</b></p>`;
        else return '';
    }

    printBarcode = () => {
        const { expirationDate, unitsInPackage } = this.state;
        const { code } = this.props;
        let template = `<html>
        <head>
            <title>${code.name}</title>
            <style>
                body {
                    font-size: 14px;
                    text-align: center;
                    padding: 0.5rem;
                    font-family: sans-serif;
                }
                #name {
                    font-size: 1.4rem;
                    font-weight: bold;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;  
                    overflow: hidden;
                    margin: 10px 0px 24px 0px;
                }
                #values {
                    text-align: left;
                    font-size: 1rem;
                    line-height: 80%;
                }
            </style>
        </head>
        <body>
            <img src="${code.image}" alt="kod" />
            <p id="name">${code.name}</p>
            <div id="values">
                ${code.code ? `<p>KOD : <b>${code.code}</b></p>` : ''}
                <p>SERIA : <b>${code.seriesNr}</b></p>
                <p>TERMIN WAŻNOŚCI : <b>${expirationDate ? expirationDate : moment(code.expirationDate).format("DD/MM/YYYY")}</b></p>
                <p>ZAWARTOŚĆ OPAK. ZB : <b>${unitsInPackage ? unitsInPackage : code.unitsInPackage}</b></p>
                ${this.renderNettoWeight()}
                ${code.tareWeight ? `<p>TARA : <b>${code.tareWeight}</b></p>` : ''}
            </div>
        </body>
        </html>`;
        let mywindow = window.open('');
        mywindow.document.write(template);
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
        mywindow.onload = function () {
            console.log("loaded");
            mywindow.print();
        };
        setTimeout(() => {
            mywindow.close();
        }, 500);
        return true;
    }

    handleInputChange = (element, e) => {
        this.setState({
            [element]: e.target.value
        });
    }

    render() {
        const { code, auth } = this.props;
        const updatePermission = AclService.isAllowedAction('generator', 'generator', 'update', auth && auth.isAdmin);
        return (
            <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                <GeneratorNewPrintComponent
                    code={code}
                    onInputChange={this.handleInputChange}
                    unitsInPackage={this.state.unitsInPackage}
                    expirationDate={this.state.expirationDate}
                    nettoWeight={this.state.nettoWeight}
                />
                {/* <div className='d-flex flex-column justify-content-center text-center w-100'>
                </div> */}
                <div style={{ display: 'flex', flex: 1, justifyContent: "flex-end", alignItems: 'center' }}>
                    {updatePermission && <Link className="btn btn-outline-primary mr-1" to={`/generator/edit/${code._id}`}>Edytuj</Link>}
                    <button className='btn btn-outline-secondary' onClick={this.printBarcode}>Drukuj</button>
                </div>
            </div>
        );
    }

};

export default class GeneratorListRecordComponent extends Component {

    render() {
        const { code, auth } = this.props;
        if (!code) return null;
        return (
            <div>
                <div className="col-md-12 Record" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: "center", alignItems: 'flex-start', paddingLeft: '30px' }}>
                        <div style={{ fontSize: 15 }}>
                            EAN: [{code.eanNr}]
                        </div>
                        <div style={{ fontSize: 15 }}>
                            SERIA: [{code.seriesNr}]
                        </div>
                        {code.nettoWeight && <div style={{ fontSize: 15 }}>
                            WAGA NETTO: [{code.nettoWeight}]
                        </div>}
                        {code.tareWeight && <div style={{ fontSize: 15 }}>
                            TARA: [{code.tareWeight}]
                        </div>}
                    </div>
                    <div style={{ flex: '2' }}>
                        <Code code={code} auth={auth} />
                    </div>
                </div>
            </div >
        )
    }
}
