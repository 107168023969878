import React, { Component, useRef } from 'react';
import ReactToPrint from 'react-to-print';

import GeneratorToPrintComponent from '../generator.to.print.component';
import './generator.list.record.css'

const Code = ({ img, series, name, productionDate, unitsInPackage, expirationDate, PAO, expiresIn }) => {
    const componentRef = useRef();

    return (
        <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
            <GeneratorToPrintComponent
                img={img}
                series={series}
                ref={componentRef}
                name={name}
                productionDate={productionDate}
                unitsInPackage={unitsInPackage}
                expirationDate={expirationDate}
                PAO={PAO}
                expiresIn={expiresIn}
            />
            <div style={{ display: 'flex', flex: 1, justifyContent: "center", alignItems: 'center' }}>
                <ReactToPrint
                    trigger={() => <button className="btn btn-outline-primary">Drukuj</button>}
                    content={() => componentRef.current} />
            </div>
        </div>
    );
};

export default class GeneratorListRecordComponent extends Component {

    parseExpirationDate = (productionDate, expirationDate) => {
        const date = new Date(expirationDate);
        const year = date.getFullYear();
        const month = date.getMonth();
        return `${month}/${year}`;
    }

    render() {
        const { ean, series, img, name, unitsInPackage, productionDate, expirationDate, PAO, expiresIn } = this.props;
        return (
            <div>
                <div className="col-md-12 Record" style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: "center", alignItems: 'flex-start', paddingLeft: '30px' }}>
                        <div style={{ fontSize: 25 }}>
                            EAN: [{ean}]
                        </div>
                        <div style={{ fontSize: 25 }}>
                            SERIA: [{series}]
                        </div>

                        {/* {name ?
                            <div style={{ fontSize: 25 }}>
                                NAZWA: [{name}]
                            </div>
                            : null
                        }

                        {unitsInPackage ?
                            <div style={{ fontSize: 25 }}>
                                ZAWARTOŚĆ OPAK. ZB. : [{unitsInPackage}szt]
                            </div>
                            : null
                        } */}

                        {/* {(PAO === true) ?
                            <div style={{ fontSize: 25 }}>
                                PAO
                          </div>
                            : null
                        } */}

                        {/* {expirationDate ?
                            <div style={{ fontSize: 25 }}>
                                TERMIN WAŻNOŚCI: {(PAO) ? 'PAO' : this.parseExpirationDate(productionDate, expirationDate)}
                            </div>
                            : null
                        } */}


                    </div>
                    <div style={{ flex: '2' }}>
                        <Code img={img} series={series} name={name} expirationDate={expirationDate} productionDate={productionDate} PAO={PAO} expiresIn={expiresIn} unitsInPackage={unitsInPackage} />
                    </div>
                </div>
            </div >
        )
    }
}
