import React from 'react';
import ComponentNavbar from "./componentNavbar";
import { toast } from 'react-toastify';
import _ from 'lodash';

export default class ErrorReport extends React.Component {

    constructor() {
        super();
        this.state = {
            files: [],
            emails: []
        }
    }

    sendReport = () => {
        const { auth, sendErrorReport, history } = this.props;
        if (!auth.email) {
            toast("Użytkownik nie ma przypisanego adresu e-mail");
            return;
        }
        if (!auth.name) {
            toast("Użytkownik nie ma przypisanej nazwy");
            return;
        }
        let data = {};
        _.each(this.refs, (input, key) => {
            data[key] = input.value;
        });
        data.email = auth.email;
        data.name = auth.name;
        if (!_.isEmpty(this.state.files)) {
            data.attachments = this.state.files;
        }
        data.cc_emails = _.uniq(_.concat(this.state.emails, [auth.email]));
        data.group = "[WMS]";
        if (!data.subject && !data.description) {
            toast("Uzupełnij temat i opis w formularzu");
            return;
        }
        data.subject = `${auth.name} | ` + data.subject;
        sendErrorReport(data, history);
    }

    addAttachments = (event) => {
        const { files } = this.state;
        var contains = _.find(files, function (file) {
            return file.name === event.target.files[0].name;
        });
        var current_size = 0;
        if (files && files instanceof Array) {
            files.map(function (file) {
                current_size += file.size;
                return null;
            });
            if ((current_size + event.target.files[0].size) / 1024 / 1024 >= 15) {
                toast("Przekroczono maksymalny rozmiar załączników 15MB");
            } else if (!contains) {
                this.setState({
                    files: _.concat(files, [event.target.files[0]])
                });
            }
        }
    }

    removeAttachment = (index) => {
        const { files } = this.state;
        this.setState({
            files: _.without(files, _.nth(files, index))
        });
    }

    renderAttachments = () => {
        const { files } = this.state;
        if (_.isEmpty(files)) {
            return null;
        }
        let badges = files.map((file, index) => {
            return (
                <h6 key={`file-${index}`}>
                    <span className="badge badge-pill badge-secondary user-select-none">
                        {file.name}
                        {"  "}
                        <button className="btn btn-sm btn-secondary" onClick={() => this.removeAttachment(index)}>X</button>
                    </span>
                </h6>
            );
        });
        return <div className="mt-2">{badges}</div>
    }

    renderEmails = () => {
        const { emails } = this.state;
        if (_.isEmpty(emails)) {
            return null;
        }
        let inputs = emails.map((email, index) => {
            return (
                <div className="row" key={`email-${index}`}>
                    <div className="col-11">
                        <input type="email" className="form-control" value={email} onChange={(e) => { this.changeEmail(e, index) }} />
                    </div>
                    <div className="col-1">
                        <button className="btn btn-sm btn-secondary" onClick={() => this.removeEmail(index)}>X</button>
                    </div>
                </div>
            );
        });
        return <div className="mt-2">{inputs}</div>
    }

    addEmail = () => {
        const { emails } = this.state;

        this.setState({
            emails: _.concat(emails, [""])
        });
    }

    removeEmail = (index) => {
        const { emails } = this.state;
        this.setState({
            emails: _.without(emails, _.nth(emails, index))
        });
    }

    changeEmail = (e, index) => {
        const { emails } = this.state;
        let newEmails = JSON.parse(JSON.stringify(emails));

        newEmails[index] = e.target.value;
        this.setState({
            emails: newEmails
        });
    }

    renderForm = () => {
        const attachments = this.renderAttachments();
        const emails = this.renderEmails();
        return (
            <div className="row mt-5">
                <div className="col-lg-12">
                    <form>
                        <div className="form-group">
                            <label>Temat</label>
                            <input ref="subject" type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Opis</label>
                            <textarea ref="description" className="form-control" />
                        </div>
                        <div className="form-group">
                            <label>Priorytet</label>
                            <select ref="priority" className="form-control" >
                                <option value="4">Niski</option>
                                <option value="3">Średni</option>
                                <option value="2">Wysoki</option>
                                <option value="1">Pilne</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Typ</label>
                            <select ref="type" className="form-control" >
                                <option value="Problem">Błąd</option>
                                <option value="Feature Request">Modyfikacja</option>
                                <option value="Question">Pytanie</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Załączniki</label>
                            <br />
                            <button type="button" className="btn btn-secondary" onClick={() => document.getElementById("file").click()}>Dodaj</button>
                            <input type="file" id="file" className="d-none" onChange={(event) => { this.addAttachments(event) }} />
                            {attachments}
                        </div>
                        <div className="form-group">
                            <label>Dodaj adresy mail do DW</label>
                            <br />
                            <button type="button" className="btn btn-secondary" onClick={() => this.addEmail()}>Dodaj</button>
                            {emails}
                        </div>
                    </form>
                </div>
            </div>
        );
    }


    render() {
        const form = this.renderForm();
        return (
            <div className="d-flex flex-column container product-container">
                <ComponentNavbar
                    title={"Zgłoś problem"}
                    button={null}
                />
                {form}
                <div className="row text-center mb-5 mt-5 justify-content-center">
                    <button className="btn btn-primary" type="button" onClick={() => { this.sendReport() }}>Wyślij</button>
                </div>
            </div>
        )
    }
}