import React from 'react';
import _ from 'lodash';
import Chart from 'chart.js';

export default class ChartComponent extends React.Component {

    renderChart = () => {
        const { type, data, name } = this.props;

        switch (type) {
            case "pie": {
                let chart = new Chart(name, {
                    type: 'pie',
                    data: data,
                    options: {}
                });
                return chart;
            }
            case "line": {
                let chart = new Chart(name, {
                    type: 'line',
                    data: data,
                    options: {}
                });
                return chart;
            }
            case "bar": {
                let chart = new Chart(name, {
                    type: 'bar',
                    data: data,
                    options: {}
                });
                return chart;
            }
            default: {
                return null;
            }
        }

    }

    render() {
        const { name, title } = this.props;
        setTimeout(() => {
            this.renderChart();
        }, 100);
        // let chart =

        return (
            <div className="card text-center">
                <div className="card-header">
                    {title}
                </div>
                <div className="card-body">
                    <canvas id={name}></canvas>
                </div>
            </div>
        );
    }
}