import React from 'react';
import { connect } from 'react-redux';
import { changeField, clearCode, clearErrors, getCode, saveCode } from '../actions/generator.code.actions';
import GeneratorEditComponent from '../components/generator.edit.component';

class GeneratorEditContainer extends React.Component {

    render() {
        return (
            <div>
                <GeneratorEditComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        code: store.code.code,
        saved: store.code.saved,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveCode: () => {
            dispatch(saveCode());
        },
        getCode: (id) => {
            dispatch(getCode(id));
        },
        clearCode: () => {
            dispatch(clearCode())
        },
        clearErrors: () => {
            dispatch(clearErrors())
        },
        changeField: (name, value) => {
            dispatch(changeField(name, value))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorEditContainer);