import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import SingleDetail from '../../application/components/singleDetail';
import DeleteConfirmationModal from '../../application/components/deleteConfirmationModal';
import SimpleModal from "../../application/components/simpleModal.component";

export default class UserDetails extends React.Component {

    constructor() {
        super();
        this.state = {
            showDeleteModal: false,
            showPasswordResetModal: false,
            activeTab: "lastDocuments-tab"
        }
    }

    handleOpenDeleteModal = () => {
        this.setState({
            showDeleteModal: true
        });
    }

    handleCloseDeleteModal = () => {
        this.setState({
            showDeleteModal: false
        });
    }

    componentDidMount() {
        const { match: { params: { id } }, getUser } = this.props;
        getUser(id);
    }

    deleteUser = () => {
        const { deleteUser, history } = this.props;
        deleteUser(history);
    }

    renderEditButton = () => {
        const { isAdmin, user } = this.props;
        if (user && isAdmin) {
            let isBlocked = user.failedLogins > 2;
            let isActive = user.active;
            return (
                <div className="btn-group">

                    <div className="dropdown">
                        <button className="btn btn-outline-info dropdown-toggle"
                            type="button" id="userActions" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Zarządzaj
                        </button>
                        <div className="dropdown-menu" aria-labelledby="userActions">
                            {isBlocked && <button className="dropdown-item" onClick={() => this.unlockAccount()}>Odblokuj</button>}
                            {isActive && <button className="dropdown-item" onClick={() => this.changeIsActive(false)}>Deaktywuj</button>}
                            {!isActive && <button className="dropdown-item" onClick={() => this.changeIsActive(true)}>Aktywuj</button>}
                            <button className="dropdown-item" data-toggle="modal" data-target="#passwordReset">Zmień hasło</button>
                        </div>
                    </div>
                    <Link to={`/users/user/${user._id}`} className='btn btn-outline-primary'>
                        Edytuj
                    </Link>
                    <button
                        type='button'
                        className='btn btn-outline-danger'
                        onClick={() => this.handleOpenDeleteModal()}> Usuń </button>
                </div>
            );
        }
        return null;
    }

    unlockAccount = () => {
        const { unlockAccount } = this.props;
        unlockAccount();
    }

    changeIsActive = (isActive) => {
        const { changeIsActive } = this.props;
        changeIsActive(isActive);
    }

    renderDetails = () => {
        const { user } = this.props;
        if (user) {
            return (
                <div className="row">
                    <div className="col">
                        <SingleDetail
                            label={'Nazwa użytkownika'}
                            value={user.name || "-"}
                        />
                        <SingleDetail
                            label={'Login'}
                            value={user.login || "-"}
                        />
                        <SingleDetail
                            label={'Telefon kontaktowy'}
                            value={user.phone || "-"}
                        />
                        <SingleDetail
                            label={'Email'}
                            value={user.email || "-"}
                        />
                        <SingleDetail
                            label={'Zablokowany'}
                            value={user.failedLogins > 2 ? "Tak" : "Nie"}
                        />
                    </div>
                    <div className="col">
                        <SingleDetail
                            label={'Dostęp do aplikacji mobilnej'}
                            value={user.allowMobile ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Administrator'}
                            value={user.isAdmin ? "Tak" : "Nie"}
                        />
                        <SingleDetail
                            label={'Rola'}
                            value={user.role ? user.role.name : "-"}
                        />
                        <SingleDetail
                            label={'Magazyn'}
                            value={user.warehouse ? _.join(user.warehouse, ", ") : "-"}
                        />
                        <SingleDetail
                            label={'Aktywny'}
                            value={user.active ? "Tak" : "Nie"}
                        />
                    </div>
                </div>
            )
        }
        return null;
    }

    setActiveTab = (name) => {
        const { activeTab } = this.state;
        if (name !== activeTab) {
            document.getElementById(activeTab).classList.remove("active");
            document.getElementById(name).classList.add("active");
            this.setState({ activeTab: name });

        }
    }

    getDetailsTabs = () => {
        return (
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <span className="nav-link active" id="lastDocuments-tab" onClick={() => this.setActiveTab("lastDocuments-tab")}>Ostatnie dokumenty</span>
                </li>
            </ul>
        )
    }

    getTabContent = () => {
        const { activeTab } = this.state;

        if (activeTab === "lastDocuments-tab") {
            return (
                <div>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <h3 className="text-center">Ostatnie dokumenty</h3>
                        </div>
                    </div>
                    <br />
                    {this.renderLastDocuments()}
                </div>
            );
        } else {
            return null;
        }
    }

    renderLastDocuments = () => {
        const { user } = this.props;
        if (_.get(user, 'lastDocuments.length', 0) > 0) {

            let statusNames = { 2: 'W realizacji', 3: 'Zrealizowany' };

            let documents = user.lastDocuments.map((document, i) => {
                return (
                    <tr key={`document${i}`}>
                        <td>{i + 1}</td>
                        <td>{document.documentNumber}</td>
                        <td>{statusNames[document.status]}</td>
                        <td>{moment(document.completionStart).format("DD.MM.YYYY HH:mm")}</td>
                        <td>{document.completionEnd ? moment(document.completionEnd).format("DD.MM.YYYY HH:mm") : "-"}</td>
                    </tr>
                )
            });

            return (
                <table className="table" style={{ width: "100%" }}>
                    <thead className="thead-dark text-center">
                        <tr>
                            <th scope="col">Lp.</th>
                            <th scope="col">Numer dokumentu</th>
                            <th scope="col">Status</th>
                            <th scope="col">Data rozpoczęcia</th>
                            <th scope="col">Data zrealizowania</th>
                        </tr>
                    </thead>
                    <tbody className="text-center">
                        {documents}
                    </tbody>
                </table>
            );
        } else {
            return (
                <div className="row text-center">
                    <div className="col-12">
                        <p className="mt-3">Brak dokumentów do wyświetlenia</p>
                    </div>
                </div>
            )
        }
    }

    renderDeleteModal = () => {
        return (
            <DeleteConfirmationModal
                title="Usuwanie użytkownika"
                msg="Czy na pewno chcesz usunąć tego użytkownika?"
                handleCloseModal={this.handleCloseDeleteModal}
                confirmAction={this.deleteUser}
            />);
    }

    generateRandomPassword = () => {
        let result = 'mag';
        let characters = '0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        document.getElementById("newPassword").value = result;
    }

    changePassword = () => {
        const { changePassword } = this.props;
        let input = document.getElementById("newPassword");
        let newPassword = input.value;
        if (newPassword.length < 6) {
            toast("Hasło musi mieć minimum 6 znaków");
            return;
        }
        changePassword(newPassword);
        document.getElementById("passwordReset-cancel").click();
        input.value = "";
    }

    renderPasswordChangeModal = () => {
        let content = (<div className="input-group mb-2 mr-sm-2">
            <div className="input-group-prepend">
                <button className="input-group-text" onClick={() => this.generateRandomPassword()}>Losowe</button>
            </div>
            <input type="text" className="form-control" id="newPassword" placeholder="Wpisz nowe hasło" />
        </div>)
        return <SimpleModal id="passwordReset" title="Zmiana hasła" content={content} button={<button className="btn btn-primary" onClick={() => this.changePassword()}>Zapisz</button>} />;
    }

    render() {
        const editButton = this.renderEditButton();
        const details = this.renderDetails();
        const tabs = this.getDetailsTabs();
        const deleteModal = this.state.showDeleteModal ? this.renderDeleteModal() : null;
        const tabsContent = this.getTabContent();
        const passwordChangeModal = this.renderPasswordChangeModal();

        return (
            <div className="d-flex flex-column container product-container">
                {deleteModal}
                {passwordChangeModal}
                <ComponentNavbar
                    title="Szczegóły użytkownika"
                    button={editButton}
                />
                {details}
                <hr />
                {tabs}
                {tabsContent}
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearUser } = this.props;
        clearUser();
    }
}