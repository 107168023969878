import _ from 'lodash';
import moment from "moment";
import React from 'react';
import ComponentNavbar from '../../application/components/componentNavbar';
import DictManager from '../../services/dict.service';

export default class DocumentEdit extends React.Component {

    componentDidMount = () => {
        const { getDocument } = this.props;
        const { id } = this.props.match.params;
        getDocument(id);
    }

    render() {
        const { document: { document }, setFieldValue, saveAdvancedEdit, history } = this.props;
        if (!document) {
            return null;
        }

        let transportOptions = DictManager.getOptionsByType(2);
        const products = this.renderProducts();

        return (<div className="container mb-5">
            <ComponentNavbar
                title="Edycja dokumentu"
                button={null}
            />
            <div className="row">
                <div className="col-8">
                    <div className="form-group">
                        <label>Numer dokumentu</label>
                        <input type="text" className="form-control" value={document.documentNumber || ""} readOnly={true} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Typ dokumentu</label>
                        <input type="text" className="form-control" value={document.type || ""} readOnly={true} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Magazyn</label>
                        <input type="text" className="form-control" value={document.warehouseFrom.name || ""} readOnly={true} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Data sprzedaży</label>
                        <input type="date" className="form-control" value={moment(document.salesDate).format("YYYY-MM-DD")} onChange={(e) => setFieldValue("salesDate", moment(e.target.value, "YYYY-MM-DD").toISOString())} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Data operacji</label>
                        <input type="date" className="form-control" value={moment(document.operationDate).format("YYYY-MM-DD")} onChange={(e) => setFieldValue("operationDate", moment(e.target.value, "YYYY-MM-DD").toISOString())} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Termin</label>
                        <input type="date" className="form-control" value={moment(document.deadline).format("YYYY-MM-DD")} onChange={(e) => setFieldValue("deadline", moment(e.target.value, "YYYY-MM-DD").toISOString())} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>Opis dokumentu</label>
                        <textarea className="form-control" value={document.info || ""} placeholder="Wpisz opis dokumentu" onChange={(e) => setFieldValue("info", e.target.value)} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label>Uwagi</label>
                        <textarea className="form-control" value={document.note || ""} placeholder="Wpisz uwagi" onChange={(e) => setFieldValue("note", e.target.value)} />
                    </div>
                </div>
                <div className="col-12">
                    <div className={`form-group ${document.status == "3" ? "" : "d-none"}`}>
                        <label>Opis do kompletacji</label>
                        <textarea className="form-control" value={document.description || ""} placeholder="Wpisz opis do kompletacji" onChange={(e) => setFieldValue("description", e.target.value)} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Typ transportu</label>
                        <select className="form-control" value={document.transportType || "0"} onChange={(e) => setFieldValue("transportType", e.target.value)}>
                            {transportOptions}
                        </select>
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Opis transportu</label>
                        <input type="text" className="form-control" value={document.transportDescription || ""} placeholder="Wpisz opis" onChange={(e) => setFieldValue("transportDescription", e.target.value)} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Ilość paczek</label>
                        <input type="number" min="0" className="form-control" value={document.packagesAmount || ""} placeholder="Wpisz ilość paczek" onChange={(e) => setFieldValue("packagesAmount", e.target.value)} />
                    </div>
                </div>
                <div className="col-12 text-center">
                    <hr />
                    <h5>Kontrahent</h5>
                </div>
                <div className="col-6">
                    <div className="form-group">
                        <label>Nazwa kontrahenta</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.name") || ""} placeholder="Wpisz nazwę kontrahenta" onChange={(e) => setFieldValue("customer.name", e.target.value)} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.mail") || ""} placeholder="Wpisz email kontrahenta" onChange={(e) => setFieldValue("customer.mail", e.target.value)} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>NIP</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.nip") || ""} placeholder="Wpisz NIP kontrahenta" onChange={(e) => setFieldValue("customer.nip", e.target.value)} />
                    </div>
                </div>

                <div className="col-4">
                    <div className="form-group">
                        <label>Telefon</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.phone") || ""} placeholder="Wpisz numer telefonu kontrahenta" onChange={(e) => setFieldValue("customer.phone", e.target.value)} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Telefon dodatkowy</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.phone2") || ""} placeholder="Wpisz numer telefonu kontrahenta" onChange={(e) => setFieldValue("customer.phone2", e.target.value)} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="form-group">
                        <label>Fax</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.fax") || ""} placeholder="Wpisz fax kontrahenta" onChange={(e) => setFieldValue("customer.fax", e.target.value)} />
                    </div>
                </div>

                <div className="col-3">
                    <div className="form-group">
                        <label>Kod pocztowy</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.address.postCode") || ""} placeholder="Wpisz kod pocztowy" onChange={(e) => setFieldValue("customer.address.postCode", e.target.value)} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Miasto</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.address.city") || ""} placeholder="Wpisz miasto" onChange={(e) => setFieldValue("customer.address.city", e.target.value)} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Ulica</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.address.street") || ""} placeholder="Wpisz nazwę ulicy" onChange={(e) => setFieldValue("customer.address.street", e.target.value)} />
                    </div>
                </div>
                <div className="col-3">
                    <div className="form-group">
                        <label>Numer budynku</label>
                        <input type="text" className="form-control" value={_.get(document, "customer.address.houseNumber") || ""} placeholder="Wpisz numer budynku" onChange={(e) => setFieldValue("customer.address.houseNumber", e.target.value)} />
                    </div>
                </div>
                <div className="col-12 text-center">
                    <hr />
                    <h5>Produkty</h5>
                    {products}
                </div>
                <div className="col-12 text-center">
                    <hr />
                    <button className="btn btn-primary" onClick={() => saveAdvancedEdit(history)}>Zapisz</button>
                </div>
            </div>
        </div>)
    }

    renderProducts() {
        const { document: { document }, setFieldValue } = this.props;

        return document.products.map((product, productIndex) => {
            return product.series.map((series, seriesIndex) => {
                return (<div className="form-group row" key={`${productIndex}-${seriesIndex}`}>
                    <div className="col-4">
                        <label>Nazwa</label>
                        <input
                            type="text"
                            className="form-control"
                            value={product.name || ""}
                            readOnly={true}
                            title={product.name}
                        />
                    </div>
                    <div className="col-4">
                        <label>Numer serii</label>
                        <input
                            type="text"
                            className="form-control"
                            value={series.seriesNr || ""}
                            placeholder="Wpisz numer serii"
                            onChange={(e) => setFieldValue(`products[${productIndex}].series[${seriesIndex}].seriesNr`, e.target.value)}
                        />
                    </div>
                    <div className="col-2">
                        <label>Data ważności</label>
                        <input
                            type="date"
                            className="form-control"
                            value={moment(series.expirationDate).format("YYYY-MM-DD")}
                            onChange={(e) => setFieldValue(`products[${productIndex}].series[${seriesIndex}].expirationDate`, moment(e.target.value, "YYYY-MM-DD").toISOString())}
                        />
                    </div>
                    <div className="col-2">
                        <label>Ilość</label>
                        <input type="text" className="form-control" value={series.amount || ""} readOnly={true} />
                    </div>
                    {/* <div className="col-1">
                        <button className="btn btn-danger mt-4">X</button>
                    </div> */}
                </div>)
            });
        });
    }

    componentWillUnmount() {
        const { clearDocument } = this.props;
        clearDocument();
    }
}