import React from 'react';
import { connect } from 'react-redux';

import { saveCode, clearCode, clearErrors, changeField } from '../actions/generator.code.actions';

import GeneratorCodeComponent from '../components/generator.code.component';

class GeneratorCodeContainer extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props && nextProps.isUpdated) this.props.history.push('/generator/list')
    }

    render() {
        return (
            <div>
                <GeneratorCodeComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        saved: store.code.saved,
        code: store.code.code,
        errors: store.code.errors
    }
}

function mapDispatchToProps(dispatch) {
    return {
        saveCode: () => {
            dispatch(saveCode());
        },
        clearCode: () => {
            dispatch(clearCode())
        },
        clearErrors: () => {
            dispatch(clearErrors())
        },
        changeField: (name, value) => {
            dispatch(changeField(name, value))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneratorCodeContainer);