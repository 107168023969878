import React from 'react';
import { connect } from 'react-redux';
import MainLoader from "../../application/components/mainLoader";
import Auth from '../components/auth.component';

import { login } from '../actions/auth.actions';

class AuthContainer extends React.Component {
    render() {
        const { isLoading } = this.props;
        if(isLoading) {
            return <MainLoader />;
        }
        return (
            <div>
                <Auth {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isLoading: store.app.isLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        login: (username, password) => {
            dispatch(login(username, password))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthContainer);