import React from 'react';

export default class DeleteConfirmationModal extends React.Component {
    render() {
        const { title, msg, handleCloseModal, confirmAction } = this.props;
        return (
            <div className="modal" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 100000, display: 'flex' }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{title}</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => handleCloseModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>{msg}</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-outline'
                                onClick={() => handleCloseModal()}
                            > Anuluj </button>
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-primary'
                                onClick={() => confirmAction()}
                            > Potwierdź </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}