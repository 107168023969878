import Api from '../../services/api';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { isLoading } from '../../application/actions/application.actions'

const namespace = 'ROLE';
export const SET_ROLE = `SET_ROLE_${namespace}`;
export const CLEAR_ROLE = `CLEAR_ROLE_${namespace}`;
export const SET_SAVED = `SET_SAVED_${namespace}`;
export const SET_USERS = `SET_USERS_${namespace}`;
export const CLEAR_USERS = `CLEAR_USERS_${namespace}`;

export const setRole = (role) => (dispatch) => {
    dispatch({
        type: SET_ROLE,
        role: role
    });
}

export const clearRole = () => (dispatch) => {
    dispatch({
        type: CLEAR_ROLE
    });
}

export const getRole = (id) => async (dispatch) => {
    dispatch(isLoading(true));

    let res = await Api.get(`/application/role/${id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setRole(res.document));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setPermission = (permission, action) => (dispatch, getState) => {
    const { role: { role } } = getState();

    let newRole = Object.assign({}, role);

    let permissionIndex = newRole.permissions.findIndex((p) => p.module === permission.module && p.controller === permission.controller);
    if (permissionIndex > -1) {
        newRole.permissions[permissionIndex].actions = _.xor(newRole.permissions[permissionIndex].actions, [action]);
    } else {
        let newPermission = Object.assign({}, permission);
        delete newPermission.label;
        newPermission.actions = [action];
        newRole.permissions.push(newPermission);
    }
    dispatch(setRole(newRole));
}


export const setOnlyOwnDocuments = (permission) => (dispatch, getState) => {
    const { role: { role } } = getState();

    let newRole = Object.assign({}, role);

    let permissionIndex = newRole.permissions.findIndex((p) => p.module === permission.module && p.controller === permission.controller);
    if (permissionIndex > -1) {
        newRole.permissions[permissionIndex].onlyOwnDocuments = !newRole.permissions[permissionIndex].onlyOwnDocuments;
    } else {
        let newPermission = Object.assign({}, permission);
        delete newPermission.label;
        newPermission.onlyOwnDocuments = true;
        newPermission.actions = [];
        newRole.permissions.push(newPermission);
    }
    dispatch(setRole(newRole));
}

export const setFilter = (value, name, permission) => (dispatch, getState) => {
    const { role: { role } } = getState();
    let newRole = Object.assign({}, role);

    let permissionIndex = newRole.permissions.findIndex((p) => p.module === permission.module && p.controller === permission.controller);
    if (permissionIndex > -1) {
        if (!_.isObject(newRole.permissions[permissionIndex].filters)) {
            newRole.permissions[permissionIndex].filters = {};
        }
        newRole.permissions[permissionIndex].filters[name] = value;
    } else {
        let newPermission = Object.assign({}, permission);
        delete newPermission.label;
        newPermission.onlyOwnDocuments = true;
        newPermission.actions = [];
        newPermission.filters = { [name]: value };
    }
    dispatch(setRole(newRole));
}

export const changeName = (name) => (dispatch, getState) => {
    const { role: { role } } = getState();

    let newRole = Object.assign({}, role);
    newRole.name = name;
    dispatch(setRole(newRole));
}

export const saveRole = () => async (dispatch, getState) => {
    const { role: { role } } = getState();

    let data = Object.assign({}, role);
    data.permissions = _.compact(data.permissions.map((permission) => {
        if (_.isEmpty(permission.actions)) {
            return null;
        }
        return permission;
    }));

    dispatch(isLoading(true));

    let res = null;

    if (role._id) {
        res = await Api.put(`/application/role/${role._id}`, data);
    } else {
        res = await Api.post("/application/role", data);
    }
    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Zapisano');
        dispatch({
            type: SET_SAVED
        });
    } else {
        toast('Wystąpił błąd');
    }
}

export const deleteRole = () => async (dispatch, getState) => {
    const { role: { role } } = getState();

    dispatch(isLoading(true));

    let res = await Api.delete(`/application/role/${role._id}`);

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Usunięto');
        dispatch({
            type: SET_SAVED
        });
    } else {
        toast('Wystąpił błąd');
    }
}

export const copyRole = (navigation) => (dispatch, getState) => {
    const { role: { role } } = getState();

    let roleCopy = JSON.parse(JSON.stringify(role));
    delete roleCopy._id;
    delete roleCopy.creator;
    delete roleCopy.createdAt;
    navigation.push("/roles/role");
    setTimeout(() => {
        dispatch(setRole(roleCopy));
        dispatch(changeName(roleCopy.name + " - kopia"));
    }, 200);
}

export const getUsers = () => async (dispatch, getState) => {
    dispatch(isLoading(true));

    let params = {
        "sort[name]": 1,
        "limit": 1000,
        "offset": 0,
        "select": "name,role",
        "active": true
    };

    let res = await Api.get("/application/user", params);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setUsers(res.documents, res.total));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setUsers = (users) => (dispatch) => {
    dispatch({
        type: SET_USERS,
        users
    });
}

export const assignRole = (users) => async (dispatch, getState) => {
    const { role: { role } } = getState();

    dispatch(isLoading(true));
    let data = {
        users: users,
        role: role._id
    };

    let res = await Api.put(`/application/role/assignRole`, data);

    dispatch(isLoading(false));

    if (res && res.success) {
        toast('Przypisano rolę');
    } else {
        toast('Wystąpił błąd');
    }
}