import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';

const namespace = 'SAVECODES';
export const SET_CODE = `SET_CODE_${namespace}`;
export const CLEAR_CODE = `CLEAR_CODE_${namespace}`;
export const SET_ERRORS = `SET_ERRORS${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS${namespace}`;
export const SET_SAVED = `SET_SAVED_${namespace}`;

export const setCode = (code) => (dispatch) => {
    dispatch({
        type: SET_CODE,
        code
    });
}

export const clearCode = () => (dispatch) => {
    dispatch({
        type: CLEAR_CODE
    });
}

export const getCode = (id) => async (dispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get(`/generator/generator/${id}?joinHistory=true`);
        dispatch(isLoading(false));
        if (res.document) {
            dispatch(setCode(res.document));
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const saveCode = () => async (dispatch, getState) => {
    try {
        const { code: { code } } = getState();
        dispatch(isLoading(true));

        let data = JSON.parse(JSON.stringify(code));

        let res = code._id ? (await Api.put(`/generator/generator/${code._id}`, data)) : (await Api.post('/generator/generator', data));
        dispatch(isLoading(true));

        if (res.success) {
            toast("Zapisano");
            dispatch({
                type: SET_SAVED
            });
        } else {
            toast('Wystąpił błąd');
            dispatch(setErrors(res.err));
        }

        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}

export const clearErrors = () => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}

export const changeField = (name, value) => (dispatch, getState) => {
    const { code: { code } } = getState();

    let newCode = Object.assign({}, code);
    newCode[name] = value;
    dispatch(setCode(newCode));
}