import Api from '../../services/api';
import { toast } from 'react-toastify';
import _ from "lodash";
import { isLoading } from '../../application/actions/application.actions'

const namespace = 'WAREHOUSEPOSITION';
export const IS_UPDATED = `IS_UPDATED${namespace}`;
export const SET_WAREHOUSE_ID = `SET_WAREHOUSE_ID${namespace}`;
export const SET_ERRORS = `SET_ERRORS${namespace}`;
export const CLEAR_ERRORS = `CLEAR_ERRORS${namespace}`;
export const SET_WAREHOUSE_POSITION_TYPE = `SET_WAREHOUSE_POSITION_TYPE${namespace}`;
export const SET_WAREHOUSE_POSITION = `SET_WAREHOUSE_POSITION${namespace}`;
export const CLEAR_WAREHOUSE_POSITION = `CLEAR_WAREHOUSE_POSITION_${namespace}`;
export const SET_POSITION_HISTORY = `SET_POSITION_HISTORY_${namespace}`;
export const CLEAR_POSITION_HISTORY = `CLEAR_POSITION_HISTORY_${namespace}`;
export const SET_HISTORY_OFFSET = `SET_HISTORY_OFFSET_${namespace}`;
export const SET_HISTORY_FILTERS = `SET_HISTORY_FILTERS_${namespace}`;
export const RESET_HISTORY_FILTERS = `RESET_HISTORY_FILTERS_${namespace}`;

export const setIsUpdated = (isUpdated) => (dispatch) => {
    dispatch({
        type: IS_UPDATED,
        isUpdated: isUpdated
    });
}

export const setWarehouseId = (warehouseId) => (dispatch) => {
    dispatch({
        type: SET_WAREHOUSE_ID,
        warehouseId: warehouseId
    });
}

export const setWarehousePositionType = (warehousePositionTypes) => (dispatch) => {
    dispatch({
        type: SET_WAREHOUSE_POSITION_TYPE,
        warehousePositionTypes: warehousePositionTypes
    });
}

export const saveWarehousePosition = (data) => async (dispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.post('/warehouse/position', data);
        dispatch(isLoading(false));

        if (res.success) {
            toast('Zapisano');
            dispatch(setIsUpdated(true))
        } else {
            toast('Wystąpił błąd');
            dispatch(setErrors(res.err))
        }
    } catch (err) {
        dispatch(isLoading(false));
    }
}

export const getWarehousePositionType = () => async (dispatch, getState) => {
    dispatch(isLoading(true));
    let res = await Api.get('/application/dictionary?type=1');

    if (res && res.success) {
        dispatch(isLoading(false));
        if (res.documents && res.documents.length > 0) dispatch(setWarehousePositionType(res.documents));
    }
}

export const setErrors = (errors) => (dispatch) => {
    dispatch({
        type: SET_ERRORS,
        errors: errors
    });
}

export const clearErrors = (errors) => (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    });
}

export const getWarehousePosition = (id) => async (dispatch, getState) => {
    dispatch(isLoading(true));
    let res = await Api.get(`/warehouse/position/${id}`);

    if (res && res.success) {
        dispatch(isLoading(false));

        if (res.documents && res.documents.length === 0) toast('Brak magazynów')
        else dispatch(setWarehousePosition(res.document));

    }
}

export const setWarehousePosition = (data) => (dispatch) => {
    dispatch({
        type: SET_WAREHOUSE_POSITION,
        warehousePosition: data
    });
}

export const clearWarehousePosition = () => (dispatch) => {
    dispatch({
        type: CLEAR_WAREHOUSE_POSITION
    });
}

export const getPositionHistory = () => async (dispatch, getState) => {
    try {
        const { warehousePosition: { history: { filters, limit, offset }, warehousePosition } } = getState();

        dispatch(isLoading(true));
        let params = {
            "sort[createdAt]": -1,
            "limit": limit,
            "offset": offset,
            "position": warehousePosition._id
        }

        if (filters.positionCode) {
            params["positionCode"] = filters.positionCode;
        }
        if (filters.documentNumber) {
            params["document.documentNumber"] = filters.documentNumber;
        }

        let res = await Api.get("/warehouse/positionHistory", params);

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch(setPositionHistory(res.documents, res.total));
        } else {
            toast('Wystąpił błąd');
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setPositionHistory = (items, total) => (dispatch) => {
    dispatch({
        type: SET_POSITION_HISTORY,
        items: items,
        total: total
    });
}

export const clearPositionHistory = () => (dispatch) => {
    dispatch({
        type: CLEAR_POSITION_HISTORY
    });
}

export const setHistoryOffset = (offset) => (dispatch) => {
    dispatch({
        type: SET_HISTORY_OFFSET,
        offset: offset
    });
}

export const setHistoryFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_HISTORY_FILTERS,
        filter,
        value
    });
}

export const resetHistoryFilters = () => dispatch => {
    dispatch({
        type: RESET_HISTORY_FILTERS
    });
}

export const saveAdvancedEdit = (history) => async (dispatch, getState) => {
    try {
        const { warehousePosition: { warehousePosition } } = getState();

        dispatch(isLoading(true));

        let data = JSON.parse(JSON.stringify(warehousePosition));

        data = _.pick(data, ["products", "type"])

        let res = await Api.put(`/warehouse/position/${warehousePosition._id}`, data);

        dispatch(isLoading(false));

        if (res && res.success) {
            history.push(`/warehouses/position/${warehousePosition._id}`);
            toast('Zapisano zmiany');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setFieldValue = (name, value) => (dispatch, getState) => {
    try {
        const { warehousePosition: { warehousePosition } } = getState();
        let newDocument = JSON.parse(JSON.stringify(warehousePosition));

        _.set(newDocument, name, value);
        dispatch(setWarehousePosition(newDocument));
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}