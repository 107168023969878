import produce from 'immer';

import { SET_USER, CLEAR_USER, SET_SAVED, SET_ERRORS, CLEAR_ERRORS } from '../actions/user.action'

const initial_state = {
    user: {
        name: "",
        isAdmin: false,
        allowMobile: false,
        role: null,
        warehouse: null,
        login: "",
        password: ""
    },
    saved: false,
    errors: {}
}

const userReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_USER:
                draft.user = action.user;
                break;
            case CLEAR_USER:
                draft.user = initial_state.user;
                draft.saved = false;
                break;
            case SET_SAVED: {
                draft.saved = true;
                break;
            }
            case SET_ERRORS: {
                draft.errors = action.errors;
                break;
            }
            case CLEAR_ERRORS: {
                draft.errors = {};
                break;
            }
            default: return draft;
        }
    })
}

export default userReducer;