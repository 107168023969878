import React from 'react';
import { connect } from 'react-redux';

import { getWarehousePosition, clearWarehousePosition, getPositionHistory, clearPositionHistory, setHistoryOffset, setHistoryFilters, resetHistoryFilters } from '../actions/warehousePosition.action'
import WarehousePositionDetailsComponent from '../components/warehousePositions/warehousePositionDetails.component';

class WarehousePositionDetailsContainer extends React.Component {

    render() {
        return (
            <div>
                <WarehousePositionDetailsComponent {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        warehousePosition: store.warehousePosition.warehousePosition,
        isAdmin: store.auth.isAdmin,
        history: store.warehousePosition.history
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getWarehousePosition: (positionId) => dispatch(getWarehousePosition(positionId)),
        clearWarehousePosition: () => {
            dispatch(clearWarehousePosition());
        },
        getPositionHistory: () => {
            dispatch(getPositionHistory());
        },
        clearPositionHistory: () => {
            dispatch(clearPositionHistory());
        },
        setHistoryOffset: (offset) => {
            dispatch(setHistoryOffset(offset));
        },
        setHistoryFilters: (name, value) => {
            dispatch(setHistoryFilters(name, value));
        },
        resetHistoryFilters: () => {
            dispatch(resetHistoryFilters());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehousePositionDetailsContainer);