import React from 'react';
import ComponentNavbar from '../../application/components/componentNavbar';
import _ from 'lodash';

export default class Profile extends React.Component {

    componentDidMount() {
        const { match: { params: { id } }, getProfile } = this.props;
        if (id) {
            getProfile(id);
        }
    }

    changeField = (e, name) => {
        const { changeField } = this.props;
        let value = e.target.value;
        changeField(value, name);
    }

    saveProfile = () => {
        const { saveProfile } = this.props;
        saveProfile();
    }

    render() {
        const { profile, errors } = this.props;

        if (!profile) {
            return null;
        }

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Edycja profilu"}
                    button={null}
                />
                <div className="row text-center mt-3">
                    <div className="col-lg-12 mt-2">
                        <label>Nazwa użytkownika</label>
                        <input type="text" className="form-control" name="name" value={profile.name || ""} onChange={(e) => this.changeField(e, 'name')} />
                        {_.get(errors, 'name') && <p className="text-danger">{errors.name}</p>}
                    </div>
                    <div className="col-lg-12 mt-2">
                        <label>Login</label>
                        <input type="text" className="form-control" name="login" value={profile.login || ""} onChange={(e) => this.changeField(e, 'login')} />
                        {_.get(errors, 'login') && <p className="text-danger">{errors.login}</p>}
                    </div>
                    <div className="col-lg-12 mt-2">
                        <label>E-mail</label>
                        <input type="email" className="form-control" name="email" value={profile.email || ""} onChange={(e) => this.changeField(e, 'email')} />
                        {_.get(errors, 'email') && <p className="text-danger">{errors.email}</p>}
                    </div>
                    <div className="col-lg-12 mt-2">
                        <label>Telefon kontaktowy</label>
                        <input type="tel" className="form-control" name="phone" value={profile.phone || ""} onChange={(e) => this.changeField(e, 'phone')} />
                        {_.get(errors, 'phone') && <p className="text-danger">{errors.phone}</p>}
                    </div>
                </div>
                <div className="row justify-content-center text-center mt-3">
                    <h4 className="mt-5">Zmiana hasła</h4>
                    <div className="col-lg-12 mt-2">
                        <label>Stare hasło</label>
                        <input type="password" className="form-control" name="oldPassword" value={profile.oldPassword || ""} onChange={(e) => this.changeField(e, 'oldPassword')} />
                        {_.get(errors, 'oldPassword') && <p className="text-danger">{errors.oldPassword}</p>}
                    </div>
                    <div className="col-lg-12 mt-2">
                        <label>Nowe hasło</label>
                        <input type="password" className="form-control" name="newPassword" value={profile.newPassword || ""} onChange={(e) => this.changeField(e, 'newPassword')} />
                        {_.get(errors, 'newPassword') && <p className="text-danger">{errors.newPassword}</p>}
                    </div>
                    <div className="col-lg-12 mt-2">
                        <label>Powtórz nowe hasło</label>
                        <input type="password" className="form-control" name="newPasswordRepeat" value={profile.newPasswordRepeat || ""} onChange={(e) => this.changeField(e, 'newPasswordRepeat')} />
                        {_.get(errors, 'newPasswordRepeat') && <p className="text-danger">{errors.newPasswordRepeat}</p>}
                    </div>
                </div>

                <div className="row justify-content-center text-center mb-5 mt-5">
                    <button className="btn btn-primary" type="button" onClick={() => { this.saveProfile() }}>Zapisz</button>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearProfile } = this.props;
        clearProfile();
    }
}