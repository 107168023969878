import _ from 'lodash';
import React from 'react';
import moment from "moment";
import { Link } from 'react-router-dom';
import SimpleModal from "../../application/components/simpleModal.component";

export default class Dashboard extends React.Component {

    state = {
        shortcutId: null
    }

    componentDidMount() {
        const { getDashboard } = this.props;
        getDashboard();
    }

    renderMessages = () => {
        const { dashboard } = this.props;

        let list = _.get(dashboard, "messages.length", 0) > 0 ? dashboard.messages.map((message, i) => {
            return (<div className="toast fade show" key={i}>
                <div className="toast-header">
                    <strong className="mr-auto">{message.title}</strong>
                    <small>{moment(message.createdAt).format("DD.MM.YYYY HH:mm")}</small>
                </div>
                <div className="toast-body">
                    {message.text}
                </div>
            </div>);
        }) : <p className="text-muted">Brak komunikatów</p>;
        return (
            <div className="list-group">
                {list}
            </div>);
    }

    deleteShortcut = () => {
        const { shortcutId } = this.state;
        const { deleteShortcut } = this.props;

        deleteShortcut(shortcutId);
        document.getElementById("shortcutDeleteModal-cancel").click();
    }

    renderShortcuts = () => {
        const { dashboard } = this.props;

        if (dashboard && !_.isEmpty(dashboard.shortcuts)) {
            return dashboard.shortcuts.map((shortcut, i) => {
                return (
                    <li key={`shortcut${i}`} className="list-group-item align-items-center">
                        <Link to={shortcut.path} className="text-dark">{shortcut.name}</Link>
                        <button type="button text-right" className="close" data-target="#shortcutDeleteModal" data-toggle="modal" onClick={() => this.setState({ shortcutId: shortcut._id })}>
                            <span>&times;</span>
                        </button>
                    </li>);
            });
        }
        return <p className="text-muted text-center">Brak dodanych skrótów</p>;
    }

    renderShortcutDeleteModal = () => {
        return <SimpleModal id="shortcutDeleteModal" title="Usuwanie skrótu" content="Czy chcesz usunąć skrót?" button={<button className="btn btn-danger" onClick={() => this.deleteShortcut()}>Usuń</button>} />
    }

    render() {
        const messages = this.renderMessages();
        const shortcuts = this.renderShortcuts();
        const shortcutDeleteModal = this.renderShortcutDeleteModal();

        return (
            <div className="d-flex flex-column p-5">
                {shortcutDeleteModal}
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <h6>Komunikaty</h6>
                        {messages}
                    </div>
                    <div className="col-7"></div>
                    <div className="col-lg-2 col-sm-12">
                        <h6 className="text-center">Skróty</h6>
                        <ul className="list-group">
                            {shortcuts}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearDashboard } = this.props;
        clearDashboard();
    }
}