import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { isLoading } from "../../application/actions/application.actions";
import Acl from '../../services/acl.service';
import Api from '../../services/api';
import DictManager from "../../services/dict.service";

const namespace = 'AUTH';
export const LOGIN = `LOGIN_${namespace}`;
export const SET_TOKEN = `SET_TOKEN_${namespace}`;
export const IS_AUTH = `IS_AUTH_${namespace}`;
export const CLEAR = `CLEAR_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;

const decodeJWT = (jwt) => {
    return jwt_decode(jwt);
}

const parseToken = (token) => dispatch => {
    const decodedToken = decodeJWT(token);
    const { company, exp, lat, isAdmin, name, _id, dbName, email } = decodedToken;
    dispatch({
        type: LOGIN,
        company,
        exp,
        lat,
        isAdmin,
        name,
        _id,
        dbName,
        email
    });
}
const setJWT = (token) => {
    localStorage.setItem('JWT', token);
}

const destroyToken = () => {
    localStorage.removeItem('JWT');
}

export const logout = () => dispatch => {
    destroyToken();
    dispatch({
        type: CLEAR
    });
}

export const login = (login, password) => (dispatch) => {
    const form = { login, password }
    Api.post('/application/login', form).then(res => {
        if (res.success) {
            if (res.token) {
                setJWT(res.token);
                dispatch(isAuth());
                DictManager.setDicts(res.settings);
            } else {
                console.error('No token returned from server')
            }
        } else {

        }
    }).catch(err => {
        console.error(err)
    })
}

export const isAuth = () => (dispatch, getState) => {
    const token = localStorage.getItem('JWT');
    dispatch(isLoading(true));
    Api.get('/application/isAuth').then(res => {
        if (res.success) {
            // ustawianie uprawnień
            if (res.user && !res.user.isAdmin && res.user.role && res.user.role.permissions) {
                Acl.setPermissions(res.user.role.permissions);
            }

            dispatch({
                type: IS_AUTH,
                isAuth: true
            })
            dispatch(isLoading(false));
            dispatch(parseToken(token));
            DictManager.setDicts(res.settings);
        } else {
            dispatch({
                type: IS_AUTH,
                isAuth: false
            })
            destroyToken();
            dispatch(isLoading(false));
            dispatch({
                type: CLEAR
            })
        }
    }).catch(err => {
        dispatch(isLoading(false));
        console.error(err)
    })
}

export const getDicts = () => async (dispatch) => {
    try {
        let res = await Api.get("/application/dictionary");

        if (_.get(res, 'documents.length') > 0) {
            DictManager.setDicts(res.documents);
        } else {
            toast("Błąd pobierania słowników");
        }
    } catch (err) {
        toast("Błąd pobierania słowników");
    }
}