import produce from "immer";

import {
    IS_LOADING,
} from '../actions/application.actions';

const applicationDefaultState = {
    isLoading: false
}

const applicationReducer = (state = applicationDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_LOADING: {
                draft.isLoading = action.isLoading;
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default applicationReducer;