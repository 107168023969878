import React from 'react';
import { Redirect } from 'react-router-dom';
import permissions from "../assets/permissions";
import _ from "lodash";
import CategoryTree from "../../application/containers/categoryTree.container";

export default class Role extends React.Component {

    componentDidMount() {
        const { match: { params: { id } }, getRole } = this.props;
        if (id) {
            getRole(id);
        }
    }

    changePermission = (permission, action) => {
        const { setPermission } = this.props;
        setPermission(permission, action);
    }

    setOnlyOwnDocuments = (permission) => {
        const { setOnlyOwnDocuments } = this.props;
        setOnlyOwnDocuments(permission);
    }

    renderDetails = () => {
        const { role } = this.props;
        return permissions.map((permission, index) => {
            let rolePermission = role.permissions.find((p) => p.module === permission.module && p.controller === permission.controller);
            let roleActions = rolePermission ? rolePermission.actions : [];
            let filters = rolePermission ? this.getFilters(rolePermission) : this.getFilters(permission);

            let actions = permission.actions.map((action) => {
                let isChecked = roleActions.includes(action.action);
                return (
                    <div className="form-check pl-4" key={`action${action.label + index}`}>
                        <input type="checkbox" className="form-check-input" checked={isChecked} onChange={() => this.changePermission(permission, action.action)} />
                        <label className="form-checl-label" title={action.info}>{action.label}</label>
                    </div>);
            });
            return (
                <div className="col-lg-4 mt-2 p-2" key={`permission${index}`}>
                    <div className="card">
                        <div className="card-header">
                            <h5>{permission.label}</h5>
                        </div>
                        <div className="card-body">
                            <ul className="list-group list-unstyled">
                                {actions}
                            </ul>
                            {filters}
                        </div>
                        <div className="card-footer">
                            <div className="form-check pl-4 mb-0" key={`filter${index}`}>
                                <input type="checkbox" className="form-check-input" defaultChecked={rolePermission && rolePermission.onlyOwnDocuments} onChange={() => this.setOnlyOwnDocuments(permission)} />
                                <label className="form-checl-label">Tylko swoje</label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    }

    getFilters(permission) {
        if (!permission) {
            return null;
        }
        let path = `${permission.module}/${permission.controller}`;
        switch (path) {
            case "product/product":
                return <div>
                    <hr />
                    <h6>Filtry</h6>
                    <CategoryTree
                        buttonClass="btn-sm"
                        content="productCategory"
                        id="productproductCategories"
                        onCheck={(checked) => this.setCategoryFilter(checked, "categories", permission)}
                        checked={_.get(permission, "filters.categories", [])}
                    />
                </div>;
            case "product/category":
                return <div>
                    <hr />
                    <h6>Filtry</h6><CategoryTree
                        buttonClass="btn-sm"
                        content="productCategory"
                        id="productcategoryCategories"
                        onCheck={(checked) => this.setCategoryFilter(checked, "categories", permission)}
                        checked={_.get(permission, "filters.categories", [])}
                    />
                </div>;
            case "statistic/report":
                return <div>
                    <hr />
                    <h6>Filtry</h6>
                    <div className="form-check pl-4" key="report1">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(1, permission)} onChange={() => this.setReportFilter(1, permission)} />
                        <label className="form-checl-label" title="Stan magazynowy produktów">Stan magazynowy produktów</label>
                    </div>
                    <div className="form-check pl-4" key="report2">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(2, permission)} onChange={() => this.setReportFilter(2, permission)} />
                        <label className="form-checl-label" title="Terminy ważności produktów">Terminy ważności produktów</label>
                    </div>
                    <div className="form-check pl-4" key="report3">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(3, permission)} onChange={() => this.setReportFilter(3, permission)} />
                        <label className="form-checl-label" title="Czasy realizacji dokumentów<">Czasy realizacji dokumentów</label>
                    </div>
                    <div className="form-check pl-4" key="report4">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(4, permission)} onChange={() => this.setReportFilter(4, permission)} />
                        <label className="form-checl-label" title="Stan magazynowy produktów z seriami">Stan magazynowy produktów z seriami</label>
                    </div>
                    <div className="form-check pl-4" key="report5">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(5, permission)} onChange={() => this.setReportFilter(5, permission)} />
                        <label className="form-checl-label" title="Raport GMP+">Raport GMP+</label>
                    </div>
                    <div className="form-check pl-4" key="report6">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(6, permission)} onChange={() => this.setReportFilter(6, permission)} />
                        <label className="form-checl-label" title="Eksport produktów">Eksport produktów</label>
                    </div>
                    <div className="form-check pl-4" key="report7">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(7, permission)} onChange={() => this.setReportFilter(7, permission)} />
                        <label className="form-checl-label" title="Produkty z dokumentów">Produkty z dokumentów</label>
                    </div>
                    <div className="form-check pl-4" key="report8">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(8, permission)} onChange={() => this.setReportFilter(8, permission)} />
                        <label className="form-checl-label" title="Dostępność produktów<">Dostępność produktów</label>
                    </div>
                    <div className="form-check pl-4" key="report8">
                        <input type="checkbox" className="form-check-input" checked={this.isReportFilterChecked(9, permission)} onChange={() => this.setReportFilter(9, permission)} />
                        <label className="form-checl-label" title="Raport wyniku inwentaryzacji">Raport wyniku inwentaryzacji</label>
                    </div>
                </div>;
            default:
                return null;
        }
    }

    isReportFilterChecked(type, permission) {
        if (_.includes(_.get(permission, "filters.types", []), type)) {
            return true;
        }
        return false;
    }

    setReportFilter(type, permission) {
        const { setFilter } = this.props;
        if (!_.isArray(_.get(permission, "filters.types"))) {
            _.set(permission, "filters.types", []);
        }
        let newFilter = _.xor(permission.filters.types, [type]);
        setFilter(newFilter, "types", permission);
    }

    setCategoryFilter(value, name, permission) {
        const { setFilter } = this.props;
        value = value.map((cat) => {
            return { _id: cat._id, name: cat.name, breadcrumb: cat.breadcrumb, ancestors: cat.ancestors };
        });
        setFilter(value, name, permission);
    }

    changeName = (e) => {
        const { changeName } = this.props;
        changeName(e.target.value)
    }

    saveRole = () => {
        const { saveRole } = this.props;
        saveRole();
    }

    render() {
        const { role, saved } = this.props;

        if (saved) {
            return <Redirect to="/roles" />
        }
        const details = this.renderDetails();
        return (
            <div className="d-flex flex-column container">
                <div className="row text-center mt-3">
                    <div className="col-lg-12">
                        <label><b>Nazwa roli</b></label>
                        <input type="text" className="form-control" name="name" value={role ? role.name : ""} onChange={(e) => this.changeName(e)} />
                    </div>
                </div>
                <div className="row mt-5">
                    {details}
                </div>
                <div className="row justify-content-center text-center mb-5 mt-5">
                    <button className="btn btn-primary" type="button" onClick={() => { this.saveRole() }}>Zapisz</button>
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearRole } = this.props;
        clearRole();
    }
}