import produce from 'immer';

import { IS_UPDATED, SET_WAREHOUSE, CLEAR_WAREHOUSE, SET_ERRORS, CLEAR_ERRORS, SET_FILTERS, RESET_FILTERS, SET_POSITIONS, SET_OFFSET, SET_STATISTICS, CLEAR_STATISTICS } from '../actions/warehouse.action';

const initial_state = {
    warehouse: null,
    errors: {},
    positionsCount: 0,
    positions: null,
    filters: {
        query: "",
        type: null,
        product: null
    },
    limit: 50,
    offset: 0,
    statistics: null
}

const generatorCodeReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_UPDATED:
                draft.isUpdated = action.isUpdated;
                break;
            case CLEAR_WAREHOUSE:
                draft.warehouse = null;
                break;
            case SET_WAREHOUSE:
                draft.warehouse = action.warehouse;
                break;
            case SET_ERRORS:
                draft.errors = action.errors;
                break;
            case CLEAR_ERRORS:
                draft.errors = {};
                break;
            case SET_FILTERS:
                draft.filters[action.filter] = action.value
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    query: "",
                    type: null,
                    product: null
                };
                draft.offset = 0;
                break;
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            case SET_POSITIONS: {
                draft.positions = action.positions;
                draft.positionsCount = action.total;
                break;
            }
            case SET_STATISTICS: {
                draft.statistics = action.statistics;
                break;
            }
            case CLEAR_STATISTICS: {
                draft.statistics = null;
                break;
            }
            default: return draft;
        }
    })
}

export default generatorCodeReducer