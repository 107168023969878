import React from 'react';

import BasicInput from '../../application/components/basicInput';

class Auth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            login: {
                value: ''
            },
            password: {
                value: ''
            },
        };
    }

    handleInputChange = (e) => {
        const name = e.target.id;
        const value = e.target.value;
        const newValue = {
            value: value,
        }

        this.setState({
            [name]: newValue
        })
    }

    onFormSubmit = (e) => {
        e.preventDefault();
        const login = this.state.login.value;
        const password = this.state.password.value;
        this.props.login(login, password)
    }

    authForm = () => {
        return (
            <form
                className="d-flex align-items-center flex-column"
                onSubmit={this.onFormSubmit}
            >
                <BasicInput
                    label="Login"
                    type="text"
                    id="login"
                    onChange={this.handleInputChange}
                    value={this.state.login.value}
                />

                <BasicInput
                    label="Hasło"
                    type="password"
                    id="password"
                    onChange={this.handleInputChange}
                    value={this.state.password.value}
                />

                <br />
                <button type="submit" className="btn btn-primary mb-2">Zaloguj</button>
            </form>
        )
    }
    render() {
        return (
            <div className="d-flex justify-content-center align-items-center container" style={{ minHeight: "100vh" }}>
                {this.authForm()}
            </div>
        )
    }
}

export default Auth;