import { toast } from 'react-toastify';
import Api from '../../services/api';

import { isLoading } from '../../application/actions/application.actions';

const namespace = 'PRODUCTS';
export const GET_PRODUCTS = `GET_PRODUCTS_${namespace}`;
export const CHANGE_OFFSET = `CHANGE_OFFSET_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;

export const getProducts = () => async (dispatch, getState) => {
    try {
        const { products: { productsPerPage, offset, filters } } = getState();
        dispatch(isLoading(true));

        const params = {
            "limit": productsPerPage,
            "offset": offset,
            "select": "name"
        }
        if (filters.name) {
            params.name = filters.name;
        }
        if (filters.ean) {
            params.ean = filters.ean;
        }
        if (filters.code) {
            params.code = filters.code;
        }
        if (filters.type !== undefined) {
            params.type = filters.type;
        }
        if (filters.active) {
            params.active = filters.active;
        }
        if (filters.gratis) {
            params.gratis = filters.gratis;
        }
        if (filters.PAO) {
            params.PAO = filters.PAO;
        }
        if (filters.categories) {
            params["categories.categoryId[in]"] = filters.categories;
        }

        let res = await Api.get('/product/product', params);

        dispatch(isLoading(false));

        if (res.success) {

            const products = res.documents;
            const total = res.total;

            dispatch({
                type: GET_PRODUCTS,
                products,
                total
            });
        } else {
           toast("Wystąpił błąd");
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast("Wystąpił błąd");
    }
}

export const changeOffset = (offset) => (dispatch) => {
    dispatch({
        type: CHANGE_OFFSET,
        offset
    });
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}