import Api from '../../services/api';
import { toast } from 'react-toastify';

import { isLoading } from '../../application/actions/application.actions'

const namespace = 'INVENTORIES';
export const SET_INVENTORIES = `SET_INVENTORIES_${namespace}`;
export const CLEAR_INVENTORIES = `CLEAR_INVENTORIES_${namespace}`;
export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;

export const setInventories = (inventories, total) => (dispatch) => {
    dispatch({
        type: SET_INVENTORIES,
        inventories: inventories,
        total: total
    });
}

export const clearInventories = () => (dispatch) => {
    dispatch({
        type: CLEAR_INVENTORIES
    });
}

export const getInventories = () => async (dispatch, getState) => {
    const { inventories: { filters, limit, offset } } = getState();
    dispatch(isLoading(true));

    let params = {
        "sort[name]": 1,
        "limit": limit,
        "offset": offset,
        "select": "name,inventoryNumber"
    }
    if (filters.query) {
        params.name = filters.query;
    }
    if (filters.completed !== undefined) {
        params.completed = filters.completed;
    }
    if(filters.dateFrom) {
        params["createdAt[gte]"] = filters.dateFrom;
    }
    if(filters.dateTo) {
        params["createdAt[lte]"] = filters.dateTo;
    }

    let res = await Api.get("/warehouse/inventory", params);

    dispatch(isLoading(false));

    if (res && res.success) {
        dispatch(setInventories(res.documents, res.total));
    } else {
        toast('Wystąpił błąd');
    }
}

export const setFilters = (filter, value) => dispatch => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
}

export const resetFilters = () => dispatch => {
    dispatch({
        type: RESET_FILTERS
    });
}

export const setOffset = (offset) => (dispatch, getState) => {
    dispatch({
        type: SET_OFFSET,
        offset: offset
    });
}
