import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import './generator.to.print.component.css';

export default class GeneratorNewPrintComponent extends Component {

    state = {
        expirationDate: null,
        unitsInPackage: null
    }

    componentDidMount = () => {
        this.updateState(this.props);
    }

    componentWillReceiveProps(newProps) {
        this.updateState(newProps);
    }

    updateState = (props) => {
        const { code: { expirationDate, unitsInPackage } } = props;
        const state = this.state;

        if (expirationDate) {
            state.expirationDate = this.parseExpirationDate(expirationDate);
        } else {
            state.expirationDate = 'brak';
        }

        state.unitsInPackage = unitsInPackage ? `[${unitsInPackage}szt]` : `[0szt]`;

        this.setState(state);
    }

    handleInputChange = (element, e) => {
        const { onInputChange } = this.props;
        onInputChange(element, e);
    }

    parseExpirationDate = (value) => {
        let date = "Brak";
        if (value && moment(value).isValid()) {
            date = `${moment(value).format("DD/MM/YYYY")}`
        }
        return date;
    }

    renderExpirationDateInput = () => {
        const { code, expirationDate } = this.props;
        return <input
            className="to-print-input screen-only"
            type='text'
            onChange={(e) => this.handleInputChange('expirationDate', e)}
            defaultValue={expirationDate ? expirationDate : this.parseExpirationDate(code.expirationDate)}
        />

    }

    renderUnitsInPackageInput = () => {
        const { code, unitsInPackage } = this.props;
        return <input
            className="to-print-input screen-only"
            type='text'
            onChange={(e) => this.handleInputChange('unitsInPackage', e)}
            defaultValue={unitsInPackage ? unitsInPackage : code.unitsInPackage}
        />
    }

    renderNettoWeighteInput = () => {
        const { code, nettoWeight } = this.props;
        return <input
            className="to-print-input screen-only"
            type='text'
            onChange={(e) => this.handleInputChange('nettoWeight', e)}
            defaultValue={nettoWeight || code.nettoWeight || 0}
        />
    }

    render() {
        const { code, expirationDate, unitsInPackage } = this.props;
        const expirationDateInput = this.renderExpirationDateInput();
        const unitsInPackageInput = this.renderUnitsInPackageInput();
        const nettoWeightInput = this.renderNettoWeighteInput();
        return (
            <div className="newcontainerToPrint">
                <div className='img-container screen-only'><img alt="" className="newimgWidth" src={`${code.image}`} /></div>
                <p className="codeName">{code.name}</p>
                {code.code && <p className="printText">KOD : <strong>{code.code}</strong></p>}
                <p className="printText">SERIA : <strong>{code.seriesNr}</strong></p>
                <p className="printText">TERMIN WAŻNOŚCI : <strong className='print-only'>{expirationDate}</strong>{expirationDateInput}</p>
                {code.unitsInPackage && <p className="printText">ZAWARTOŚĆ OPAK. ZB. : <strong className='print-only'>{unitsInPackage}</strong>{unitsInPackageInput}</p>}
                {code.nettoWeight && <p className="printText">WAGA NETTO : {nettoWeightInput}</p>}
                {code.tareWeight && <p className="printText">TARA : <strong>{code.tareWeight}</strong></p>}
            </div>
        )
    }
}
