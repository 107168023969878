import React from 'react';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleModal from "./simpleModal.component";
import AclService from '../../services/acl.service';

class Navbar extends React.Component {

    renderShortcutModal = () => {
        let content = (<div className="form-group">
            <label>Nazwa skrótu</label>
            <input type="text" className="form-control" placeholder="Wpisz nazwę skrótu" id="shortcutName" />
        </div>)

        return <SimpleModal title="Dodaj skrót" content={content} id="shortcutModal" button={<button type="button" className="btn btn-primary" onClick={() => this.saveShortcut()}>Zapisz</button>} />
    }

    saveShortcut = () => {
        const { saveShortcut } = this.props;
        let input = document.getElementById("shortcutName");
        let name = input.value;
        if (name.length < 1) {
            toast("Nazwa nie może być pusta");
            return;
        }
        saveShortcut(name, window.location.pathname);
        input.value = "";
        document.getElementById("shortcutModal-cancel").click();
    }

    render() {
        let userId = this.props.auth._id;
        const shortCutModal = this.renderShortcutModal();

        return (
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                {shortCutModal}
                <Link className="navbar-brand" to="/">WMS</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="mainNavbar">
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        {AclService.setIsAllowedState('document', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/documents">Dokumenty</Link>
                        </li> : null}
                        {AclService.setIsAllowedState('product', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/products">Produkty</Link>
                        </li> : null}
                        {AclService.setIsAllowedState('generator', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/generator/list">Generator Kodów</Link>
                        </li> : null}
                        {AclService.setIsAllowedState('warehouse', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/warehouses">Magazyny</Link>
                        </li> : null}
                        {AclService.isAllowedAction('warehouse', 'inventory', 'get', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/inventories">Inwentaryzacje</Link>
                        </li> : null}
                        {AclService.isAllowedAction('application', 'user', 'get', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/users">Użytkownicy</Link>
                        </li> : null}
                        {AclService.isAllowedAction('application', 'role', 'get', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/roles">Role</Link>
                        </li> : null}
                        {AclService.isAllowedAction('statistic', 'report', 'create', this.props.auth.isAdmin) ? <li className="nav-item">
                            <Link className="nav-link" to="/reports">Raporty</Link>
                        </li> : null}
                        {this.props.auth.isAdmin ? <li className="nav-item">
                            <Link className="nav-link" to="/settings">Ustawienia</Link>
                        </li> : null}
                        <li className="nav-item">
                            <Link className="nav-link" to={`/profile/${userId}`}>Profil</Link>
                        </li>
                    </ul>
                    <form className="form-inline">
                        <button className="btn btn-info mr-2"
                            data-toggle="modal"
                            data-target="#shortcutModal"
                            type="button"
                        >
                            Dodaj skrót
                        </button>
                        {shortCutModal}
                        <Link className="btn btn-secondary mr-2" to="/report" type="button">
                            Zgłoś problem
                        </Link>
                        <button className="btn btn-primary"
                            onClick={this.props.logout}
                            type="button"
                        >
                            Wyloguj
                        </button>
                    </form>
                </div>
            </nav>);
    }
}

export default Navbar;