import produce from 'immer';

import {
    IS_UPDATED,
    SET_WAREHOUSE_ID,
    SET_ERRORS,
    CLEAR_ERRORS,
    SET_WAREHOUSE_POSITION_TYPE,
    SET_WAREHOUSE_POSITION,
    CLEAR_WAREHOUSE_POSITION,
    SET_POSITION_HISTORY,
    CLEAR_POSITION_HISTORY,
    SET_HISTORY_FILTERS,
    SET_HISTORY_OFFSET,
    RESET_HISTORY_FILTERS
} from '../actions/warehousePosition.action';

const initial_state = {
    warehouseCode: '',
    column: '',
    row: '',
    warehouseId: null,
    isUpdated: false,
    errors: {},
    warehousePositionTypes: [],
    warehousePosition: null,
    history: {
        items: null,
        filters: {
            positionCode: ""
        },
        limit: 10,
        offset: 0,
        total: 0
    }
}

const generatorCodeReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case IS_UPDATED:
                draft.isUpdated = action.isUpdated;
                break;
            case SET_WAREHOUSE_ID:
                draft.warehouseId = action.warehouseId;
                break;
            case SET_ERRORS:
                draft.errors = action.errors;
                break;
            case CLEAR_ERRORS:
                draft.errors = {};
                break;
            case SET_WAREHOUSE_POSITION_TYPE:
                draft.warehousePositionTypes = action.warehousePositionTypes;
                break;
            case SET_WAREHOUSE_POSITION:
                draft.warehousePosition = action.warehousePosition;
                break;
            case CLEAR_WAREHOUSE_POSITION:
                draft.warehousePosition = null;
                break;
            case SET_POSITION_HISTORY:
                draft.history.items = action.items;
                draft.history.total = action.total;
                break;
            case CLEAR_POSITION_HISTORY:
                draft.history.items = null;
                draft.history.total = 0;
                break;
            case SET_HISTORY_OFFSET:
                draft.history.offset = action.offset;
                break;
            case SET_HISTORY_FILTERS:
                draft.history.filters = action.filters;
                draft.history.offset = 0;
                break;
            case RESET_HISTORY_FILTERS:
                draft.history.filters = {
                    positionCode: ""
                };
                draft.history.offset = 0;
                break;
            default: return draft;
        }
    })
}

export default generatorCodeReducer