import React from 'react';

export default class DocumentShortTwoValues extends React.Component {
    render() {
        const { width, height, label1, label2, value1, value2 } = this.props
        return (
            <div className="document-short-two-values document-border pb-3" style={{ width: width, height: height || null, }}>
                <p>{label1}: <strong>{value1}</strong></p>
                <p>{label2}: <strong>{value2}</strong></p>
            </div>
        )
    }
} 