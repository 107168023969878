import React from 'react';
import { connect } from 'react-redux';
import ErrorReport from '../components/errorReport';
import { sendErrorReport } from "../actions/application.actions";

class ErrorReportContainer extends React.Component {
    render() {
        return (
            <div>
                <ErrorReport {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendErrorReport: (data, history) => {
            dispatch(sendErrorReport(data, history));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorReportContainer);