import React from 'react';

export default class ComponentNavbar extends React.Component {
    render() {
        const { title, button } = this.props;

        return (
            <div className="row" style={{ marginTop: 16 }}>
                <div className='col-md-12'>
                    <div className='d-flex align-items-center row'>
                        <div className='col-md-3'></div>
                        <div className='col-md-6'>
                            <h3 className='text-center'>{title}</h3>
                        </div>
                        <div className='col-md-3 d-flex justify-content-end'>
                            {button}
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        )

    }
}