import produce from 'immer';
import moment from "moment";
import { CLEAR_INVENTORIES, RESET_FILTERS, SET_FILTERS, SET_INVENTORIES, SET_OFFSET } from '../actions/inventories.action';

const initial_state = {
    inventories: [],
    filters: {
        dateFrom: moment().startOf('month').toISOString(),
        dateTo: moment().endOf('day').toISOString(),
        completed: undefined,
        query: ''
    },
    total: 0,
    limit: 25,
    offset: 0
}

const inventoriesReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_INVENTORIES:
                draft.inventories = action.inventories;
                draft.total = action.total;
                break;
            case CLEAR_INVENTORIES:
                draft.inventories = [];
                draft.total = 0;
                break;
            case SET_FILTERS:
                draft.filters[action.filter] = action.value
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    dateFrom: moment().startOf('month').toISOString(),
                    dateTo: moment().endOf('day').toISOString(),
                    closed: undefined,
                    query: ''
                };
                draft.offset = 0;
                break;
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            default: return draft;
        }
    })
}

export default inventoriesReducer;