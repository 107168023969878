import React from 'react';
import { connect } from 'react-redux';
import {
    getWarehousePosition,
    clearWarehousePosition,
    setFieldValue,
    saveAdvancedEdit
} from '../actions/warehousePosition.action';
import WarehousePositionEdit from '../components/warehousePositions/warehousePositionEdit.component';

class WarehousePositionEditContainer extends React.Component {

    render() {
        return (
            <div>
                <WarehousePositionEdit
                    {...this.props}
                />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        auth: store.auth,
        isAdmin: store.auth.isAdmin,
        warehousePosition: store.warehousePosition.warehousePosition
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getWarehousePosition: (id) => {
            dispatch(getWarehousePosition(id));
        },
        clearWarehousePosition: () => {
            dispatch(clearWarehousePosition());
        },
        setFieldValue: (name, value) => {
            dispatch(setFieldValue(name, value));
        },
        saveAdvancedEdit: (history) => {
            dispatch(saveAdvancedEdit(history));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WarehousePositionEditContainer);