import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import ComponentNavbar from '../../application/components/componentNavbar';
import permissions from "../assets/permissions";
import { toast } from 'react-toastify';
import SimpleModal from "../../application/components/simpleModal.component";
import _ from 'lodash';

export default class RoleDetails extends React.Component {

    state = {
        toAssign: []
    }

    componentDidMount() {
        const { match: { params: { id } }, getRole } = this.props;
        getRole(id);
    }

    deleteRole = () => {
        const { deleteRole } = this.props;
        deleteRole();
    }

    copyRole = () => {
        const { copyRole, history } = this.props;
        copyRole(history);
    }

    assignRole = () => {
        const { toAssign } = this.state;
        const { assignRole } = this.props;
        if (_.isEmpty(toAssign)) {
            toast("Nie wybrano żadnego uzytkownika");
            return;
        }
        assignRole(toAssign);
        document.getElementById("assignRole-cancel").click();
    }

    getRoleAssignModal = () => {
        const { toAssign } = this.state;
        const { users, role } = this.props;
        let form = users.map((user, index) => {
            let checked = toAssign.indexOf(user._id) > -1;
            let disabled = user.role === role._id;
            return (<div className="col-6" key={index}>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" checked={checked || disabled} onChange={() => this.setState({ toAssign: _.xor(toAssign, [user._id]) })} disabled={disabled} />
                    <label className="form-check-label">
                        {user.name}
                    </label>
                </div>
            </div>);
        });
        let content = (<div className="row">
            {form}
        </div>)
        return <SimpleModal id="assignRole" title="Przypisz rolę" content={content} button={<button className="btn btn-primary" onClick={() => this.assignRole()}>Zapisz</button>} />;
    }

    renderEditButton = () => {
        const { isAdmin, role, getUsers } = this.props;
        if (role && isAdmin) {
            return (
                <div className="btn-group">
                    <div className="dropdown">
                        <button className="btn btn-outline-info dropdown-toggle"
                            type="button" id="roleActions" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Akcje
                        </button>
                        <div className="dropdown-menu" aria-labelledby="roleActions">
                            <button className="dropdown-item" onClick={() => this.copyRole()}>Kopiuj</button>
                            <button className="dropdown-item" data-toggle="modal" data-target="#assignRole" onClick={() => getUsers()}>Przypisz</button>
                        </div>
                    </div>
                    <Link to={`/roles/role/${role._id}`} className='btn btn-outline-primary'>
                        Edytuj
                    </Link>
                    <button
                        type='button'
                        className='btn btn-outline-danger'
                        onClick={() => this.deleteRole()}> Usuń </button>
                </div>
            );
        }
        return null;
    }

    renderDetails = () => {
        const { role, saved } = this.props;

        if (saved) {
            return <Redirect to="/roles" />
        }

        if (role) {
            return permissions.map((permission, index) => {
                let rolePermission = role.permissions.find((p) => p.module === permission.module && p.controller === permission.controller);
                let roleActions = rolePermission ? rolePermission.actions : [];
                if (roleActions.length === 0) {
                    return null;
                }
                let actions = permission.actions.map((action) => {
                    if (roleActions.includes(action.action)) {
                        return <li key={`action${action.label + index}`}>{action.label}</li>;
                    }
                    return null;
                });

                return (
                    <div className="col-lg-4 mt-2 p-2" key={`permission${index}`}>
                        <div className="card">
                            <div className="card-header">
                                <h5>{permission.label}</h5>
                            </div>
                            <div className="card-body">
                                <ul className="list-group list-unstyled">
                                    {actions}
                                </ul>
                            </div>
                            <div className="card-footer">
                                {_.size(rolePermission.filters) > 0 && <span className="text-danger text-bold">Dodano filtry</span>}
                                <p>Tylko swoje: {rolePermission.onlyOwnDocuments ? "Tak" : "Nie"} </p>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return null;
    }

    render() {
        const { role } = this.props;
        const editButton = this.renderEditButton();
        const details = this.renderDetails();
        const assignModal = this.getRoleAssignModal();
        return (
            <div className="d-flex flex-column container product-container">
                {assignModal}
                <ComponentNavbar
                    title={role ? role.name : "Szczegóły roli"}
                    button={editButton}
                />
                <div className="row">
                    {details}
                </div>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearRole } = this.props;
        clearRole();
    }
}