import produce from 'immer';

import { SET_INVENTORY, CLEAR_INVENTORY, SET_SAVED, SET_ERRORS, CLEAR_ERRORS, SET_INVENTORY_HISTORY, CLEAR_INVENTORY_HISTORY, SET_HISTORY_OFFSET, SET_HISTORY_FILTERS, RESET_HISTORY_FILTERS } from '../actions/inventory.action'

const initial_state = {
    inventory: {
        name: "",
        completed: false,
        warehouse: null
    },
    saved: false,
    errors: {},
    history: {
        items: null,
        filters: {
            product: null,
            positionCode: "",
            user: null,
            seriesNr: ""
        },
        limit: 10,
        offset: 0,
        total: 0
    }
}

const inventoryReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_INVENTORY:
                draft.inventory = action.inventory;
                break;
            case CLEAR_INVENTORY:
                draft.inventory = initial_state.inventory;
                draft.saved = false;
                break;
            case SET_SAVED: {
                draft.saved = true;
                break;
            }
            case SET_ERRORS: {
                draft.errors = action.errors;
                break;
            }
            case CLEAR_ERRORS: {
                draft.errors = {};
                break;
            }
            case SET_INVENTORY_HISTORY:
                draft.history.items = action.items;
                draft.history.total = action.total;
                break;
            case CLEAR_INVENTORY_HISTORY:
                draft.history.items = null;
                draft.history.total = 0;
                break;
            case SET_HISTORY_OFFSET:
                draft.history.offset = action.offset;
                break;
            case SET_HISTORY_FILTERS:
                draft.history.filters[action.filter] = action.value;
                draft.history.offset = 0;
                break;
            case RESET_HISTORY_FILTERS:
                draft.history.filters = {
                    product: null,
                    positionCode: "",
                    user: null,
                    seriesNr: ""
                };
                draft.history.offset = 0;
                break;
            default: return draft;
        }
    })
}

export default inventoryReducer;