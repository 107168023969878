import produce from "immer";

import {
    SET_DOCUMENT,
    SET_RESERVATIONS,
    CLEAR_DOCUMENT,
    CLEAR_RESERVATIONS,
    SET_PRINT_OPTIONS,
    RESET_PRINT_OPTIONS,
    SET_SUGGESTED_SERIES,
    CLEAR_SUGGESTED_SERIES
} from '../actions/document.actions';

const productDefaultState = {
    document: null,
    reservations: [],
    printOptions: {
        note: true,
        info: true,
        name: true,
        seriesNr: true,
        code: true,
        amount: true,
        expirationDate: true,
        productDescription: true
    },
    suggestedSeries: []
}

const documentReducer = (state = productDefaultState, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_DOCUMENT: {
                draft.document = action.document;
                break;
            }
            case SET_RESERVATIONS: {
                draft.reservations = action.reservations;
                break;
            }
            case CLEAR_DOCUMENT: {
                draft.document = null;
                break;
            }
            case CLEAR_RESERVATIONS: {
                draft.reservations = [];
                break;
            }
            case SET_PRINT_OPTIONS: {
                draft.printOptions[action.name] = action.value;
                break;
            }
            case RESET_PRINT_OPTIONS: {
                draft.printOptions = {
                    note: true,
                    info: true,
                    name: true,
                    seriesNr: true,
                    code: true,
                    amount: true,
                    expirationDate: true,
                    productDescription: true
                };
                break;
            }
            case SET_SUGGESTED_SERIES: {
                draft.suggestedSeries = action.series;
                break;
            }
            case CLEAR_SUGGESTED_SERIES: {
                draft.suggestedSeries = [];
                break;
            }
            default: {
                return draft;
            }
        }
    });

}

export default documentReducer;