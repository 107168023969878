import React from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../../application/components/pagination';
import ComponentNavbar from '../../application/components/componentNavbar';
import Autocomplete from '../../application/containers/autocomplete.container';

export default class Users extends React.Component {

    state = {
        page: 1
    }

    componentDidMount = () => {
        const { getUsers } = this.props;
        getUsers();
    }

    renderCreateButton = () => {
        const { isAdmin } = this.props;
        if (isAdmin) {
            return (
                <div>
                    <Link to={"/users/user"} className='btn btn-outline-primary'>
                        Dodaj
                    </Link>
                </div>
            );
        }
        return null;
    }

    renderItems = () => {
        const { users } = this.props;

        if (users && users.length > 0) {
            return users.map((user, index) => {
                let isBlocked = user.failedLogins > 2;
                let isActive = user.active;
                return (<Link key={index} to={`users/details/${user._id}`} className={`d-flex list-group-item list-group-item-action align-items-center justify-content-between ${!isActive ? 'list-group-item-secondary' : ''}`}>
                    {user.name}
                    {isBlocked && <span class="badge badge-danger badge-pill">Zablokowany</span>}
                </Link>);
            });
        } else {
            return (
                <ul className="d-flex justify-content-center mt-5">
                    <div>Brak użytkowników</div>
                </ul>);
        }
    }

    changeFilter = (e, name) => {
        const { setFilters, getUsers } = this.props;
        let value = e.target.value;
        if (name === "active" && value === "0") {
            value = undefined;
        }
        setFilters(name, value);
        this.setState({ page: 1 });
        getUsers();
    }

    changeAutocompleteFilter = (name, value) => {
        const { setFilters, getUsers } = this.props;
        setFilters(name, value);
        this.setState({ page: 1 });
        getUsers();
    }

    resetFilters = () => {
        const { resetFilters, getUsers } = this.props;
        resetFilters();
        this.setState({ page: 1 });
        getUsers();
    }

    renderSearchBar = () => {
        const { filters } = this.props;

        return (
            <div className="mb-4">
                <div className="row">
                    <div className="col-md-3 offset-4">
                        {filters.role && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("role", null)}>
                                    {filters.role.name}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                    <div className="col-md-2">
                        {filters.warehouse && (
                            <h6>
                                <span className="badge badge-pill badge-secondary user-select-none" onClick={() => this.changeAutocompleteFilter("warehouse", null)}>
                                    {filters.warehouse.code}
                                    {"  "}
                                    <button className="btn btn-sm btn-secondary">X</button>
                                </span>
                            </h6>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <label>Wyszukaj użytkownika</label>
                        <input type="text" className="form-control" placeholder="Wpisz nazwę" value={filters.query} onChange={(e) => this.changeFilter(e, 'query')} />
                    </div>
                    <div className="col-md-2">
                        <label>Aktywny</label>
                        <select className="form-control" value={filters.active || "0"} onChange={(e) => this.changeFilter(e, 'active')} >
                            <option value="0">Wybierz</option>
                            <option value="true">Tak</option>
                            <option value="false">Nie</option>
                        </select>
                    </div>
                    <div className="col-md-2">
                        <label>Rola</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("role", value)} content="role" name="roleSearch" />
                    </div>
                    <div className="col-md-3">
                        <label>Magazyn</label>
                        <Autocomplete onSelect={(value) => this.changeAutocompleteFilter("warehouse", value)} content="warehouse" name="warehouseSearch" />
                    </div>
                    <div className="col-md-2 pt-1 text-right">
                        <button className="btn btn-secondary mt-4" onClick={() => this.resetFilters()}>Usuń filtry</button>
                    </div>
                </div>
            </div>);
    }


    setPage = (page) => {
        this.setState({ page: page });
    }

    renderPagination = () => {
        const { total, offset, limit, users, getUsers, setOffset } = this.props;
        const { page } = this.state;

        if (users && users.length > 0) return (
            <Pagination
                getDocuments={getUsers}
                changeOffset={setOffset}
                offset={offset}
                total={total}
                perPage={limit}
                setPage={this.setPage}
                page={page}
            />
        )
    }

    render() {
        const items = this.renderItems();
        const createButton = this.renderCreateButton();
        const searchBar = this.renderSearchBar();
        const pagination = this.renderPagination();

        return (
            <div className="d-flex flex-column container">
                <ComponentNavbar
                    title={"Lista użytkowników"}
                    button={createButton}
                />
                {searchBar}
                <ul className="list-group document-list mb-5">
                    {pagination}
                    {items}
                    {pagination}
                </ul>
            </div>
        )
    }

    componentWillUnmount = () => {
        const { clearUsers, resetFilters } = this.props;
        clearUsers();
        resetFilters();
    }
}