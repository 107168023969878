import produce from 'immer';

import { SET_USERS, CLEAR_USERS, SET_FILTERS, RESET_FILTERS, SET_OFFSET } from '../actions/users.action'

const initial_state = {
    users: [],
    filters: {
        role: null,
        warehouse: null,
        query: "",
        active: undefined
    },
    total: 0,
    offset: 0,
    limit: 25
}

const usersReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_USERS:
                draft.users = action.users;
                draft.total = action.total;
                break;
            case CLEAR_USERS:
                draft.users = [];
                draft.total = 0;
                break;
            case SET_FILTERS:
                draft.filters[action.filter] = action.value;
                draft.offset = 0;
                break;
            case RESET_FILTERS:
                draft.filters = {
                    role: "",
                    warehouse: "",
                    query: "",
                    active: undefined
                };
                draft.offset = 0;
                break;
            case SET_OFFSET: {
                draft.offset = action.offset;
                break;
            }
            default: return draft;
        }
    })
}

export default usersReducer;