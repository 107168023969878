import React from 'react';
import { connect } from 'react-redux';
import { getCategoryTree, clearCategoryTree, saveCategory, deleteCategory } from '../actions/categoryTree.actions';
import CategoryTree from '../components/categoryTree.component';

class CategoryTreeContainer extends React.Component {
    render() {
        return (
            <div>
                <CategoryTree {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        categories: store.categoryTree.categories,
        auth: store.auth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCategoryTree: (content, id) => {
            dispatch(getCategoryTree(content, id));
        },
        clearCategoryTree: (id) => {
            dispatch(clearCategoryTree(id));
        },
        saveCategory: (content, id, data) => {
            dispatch(saveCategory(content, id, data))
        },
        deleteCategory: (content, categoryId, id) => {
            dispatch(deleteCategory(content, categoryId, id))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTreeContainer);