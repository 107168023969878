import Api from '../../services/api';
import { toast } from 'react-toastify';

const namespace = 'AUTOCOMPLETE';
export const SET_OPTIONS = `SET_OPTIONS_${namespace}`;
export const CLEAR_OPTIONS = `CLEAR_OPTIONS_${namespace}`;

export const setOptions = (documents, name) => (dispatch, getState) => {
    const { autocomplete: { options } } = getState();

    let newOptions = Object.assign({}, options);
    newOptions[name] = documents;

    dispatch({
        type: SET_OPTIONS,
        options: newOptions
    });
}

export const clearOptions = (name) => (dispatch, getState) => {
    const { autocomplete: { options } } = getState();

    let newOptions = Object.assign({}, options);

    if (newOptions[name]) {
        delete newOptions[name];
        dispatch({
            type: SET_OPTIONS,
            options: newOptions
        });
    }
}

export const getOptions = (content, name, query, fields) => async (dispatch) => {
    switch (content) {
        case 'warehouse': {
            dispatch(getWarehouses(name, query, fields));
            break;
        }
        case 'position': {
            dispatch(getPositions(name, query, fields));
            break;
        }
        case 'role': {
            dispatch(getRoles(name, query, fields));
            break;
        }
        case 'product': {
            dispatch(getProducts(name, query, fields));
            break;
        }
        case 'user': {
            dispatch(getUsers(name, query, fields));
            break;
        }
        default: {
            return;
        }
    }
}

const getWarehouses = (name, query, fields) => async (dispatch) => {
    let res = await Api.get("/warehouse/warehouse", { select: fields ? fields : 'name,code', name: query, limit: 5 });

    if (res && res.success) {
        dispatch(setOptions(res.documents, name));
    } else {
        toast('Wystąpił błąd');
    }
}

const getPositions = (name, query, fields) => async (dispatch) => {
    let res = await Api.get("/warehouse/position", { select: fields ? fields : 'code', code: query, limit: 5 });

    if (res && res.success) {
        dispatch(setOptions(res.documents, name));
    } else {
        toast('Wystąpił błąd');
    }
}

const getRoles = (name, query, fields) => async (dispatch) => {
    let res = await Api.get("/application/role", { select: fields ? fields : 'name', name: query, limit: 5 });

    if (res && res.success) {
        dispatch(setOptions(res.documents, name));
    } else {
        toast('Wystąpił błąd');
    }
}

const getProducts = (name, query, fields) => async (dispatch) => {
    let res = await Api.get("/product/product", { select: fields ? fields : 'name,ean,expiresIn,code', "name[orin]": query, "code[orin]": query, limit: 10 });

    if (res && res.success) {
        dispatch(setOptions(res.documents, name));
    } else {
        toast('Wystąpił błąd');
    }
}

const getUsers = (name, query, fields) => async (dispatch) => {
    let res = await Api.get("/application/user", { select: fields ? fields : 'name', name: query, limit: 5 });

    if (res && res.success) {
        dispatch(setOptions(res.documents, name));
    } else {
        toast('Wystąpił błąd');
    }
}



