import produce from 'immer';

import { SET_ROLE, CLEAR_ROLE, SET_SAVED, SET_USERS, CLEAR_USERS } from '../actions/role.action'

const initial_state = {
    role: {
        name: "Nowa rola",
        permissions: [],
    },
    saved: false,
    users: []
}

const roleReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_ROLE:
                draft.role = action.role;
                break;
            case CLEAR_ROLE:
                draft.role = {
                    name: "Nowa rola",
                    permissions: []
                }
                draft.saved = false;
                break;
            case SET_SAVED: {
                draft.saved = true;
                break;
            }
            case SET_USERS: {
                draft.users = action.users;
                break;
            }
            case CLEAR_USERS: {
                draft.users = [];
                break;
            }
            default: return draft;
        }
    })
}

export default roleReducer;