import _ from 'lodash';
import React from 'react';
import { Link } from "react-router-dom";
import DictManager from "../../services/dict.service";

export default class DocumentListItem extends React.Component {

    statusClass = (status) => {
        switch (status) {
            case '1':
                return 'document-status-1';
            case '2':
                return 'document-status-2';
            case '3':
                return 'document-status-3';
            default:
                return null
        }
    }

    inspectionStatusClass = (status) => {
        switch (status) {
            case '1':
                return 'inspection-status-1';
            case '2':
                return 'inspection-status-2';
            case '3':
                return 'inspection-status-3';
            default:
                return null
        }
    }

    render() {
        const {
            document: {
                _id,
                documentNumber,
                status,
                transportType,
                transportDescription,
                customer,
                completionEnabled,
                inspection
            },
            isActive
        } = this.props;

        return (
            <Link
                className={`list-group-item list-group-item-action d-flex flex-row justify-content-between align-items-center ${completionEnabled === false ? "completion-disabled" : ""} ${isActive ? "active-document" : ""}`}
                to={`/documents/details/${_id}`}
            >
                <div className="w-75">
                    <b>{documentNumber}</b> {_.get(customer, 'name') ? ` | ${_.get(customer, 'name')}` : ""}
                </div>
                <div className="justify-content-end">
                    {transportType && <b>| {DictManager.getNameByValue(2, transportType)} |</b>}
                    {transportDescription && <span title={transportDescription}> {_.truncate(transportDescription, { length: 10 })}</span>}
                </div>
                <div className="d-flex flex-row">
                    <div className={`document-list-status mr-1 ${this.inspectionStatusClass(_.get(inspection, "status", "1"))}`}></div>
                    <div className={`document-list-status ${this.statusClass(status)}`}></div>
                </div>
            </Link>
        )
    }
}