import React from 'react';
import { connect } from 'react-redux';
import { getUsers, clearUsers, setFilters, resetFilters, setOffset } from '../actions/users.action'
import Users from '../components/users.component';

class UsersContainer extends React.Component {
    render() {
        return (
            <div>
                <Users {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        users: store.users.users,
        total: store.users.total,
        isAdmin: store.auth.isAdmin,
        filters: store.users.filters,
        offset: store.users.offset,
        limit: store.users.limit
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUsers: () => {
            dispatch(getUsers());
        },
        clearUsers: () => {
            dispatch(clearUsers());
        },
        setFilters: (filter, value) => {
            dispatch(setFilters(filter, value))
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
        setOffset: (offset) => {
            dispatch(setOffset(offset));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);