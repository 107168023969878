import FileSaver from "file-saver";
import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';
import _ from "lodash";

const namespace = 'DOCUMENT';
export const SET_DOCUMENT = `SET_DOCUMENT_${namespace}`;
export const SET_RESERVATIONS = `SET_RESERVATIONS_${namespace}`;
export const CLEAR_DOCUMENT = `CLEAR_DOCUMENT_${namespace}`;
export const CLEAR_RESERVATIONS = `CLEAR_RESERVATIONS_${namespace}`;
export const SET_PRINT_OPTIONS = `SET_PRINT_OPTIONS_${namespace}`;
export const RESET_PRINT_OPTIONS = `RESET_PRINT_OPTIONS_${namespace}`;
export const SET_SUGGESTED_SERIES = `SET_SUGGESTED_SERIES_${namespace}`;
export const CLEAR_SUGGESTED_SERIES = `CLEAR_SUGGESTED_SERIES_${namespace}`;

export const getDocument = (id, withReservations = false) => (dispatch) => {
    dispatch(isLoading(true));

    Api.get(`/document/document/${id}`, { checkAmount: true }).then(res => {
        if (res.success) {
            dispatch(setDocument(res.document));
            if (withReservations) {
                dispatch(getReservations());
            }
        } else {
            toast("Błąd pobierania dokumentu")
        }

        dispatch(isLoading(false));

    }).catch(err => {
        dispatch(isLoading(false));
        console.error(err);
    })
}

export const setDocument = (document) => (dispatch) => {
    dispatch({
        type: SET_DOCUMENT,
        document: document
    });
}

export const clearDocument = () => dispatch => {
    dispatch({
        type: CLEAR_DOCUMENT
    });
}

export const setReservations = (reservations) => (dispatch) => {
    dispatch({
        type: SET_RESERVATIONS,
        reservations: reservations
    });
}

export const clearReservations = () => dispatch => {
    dispatch({
        type: CLEAR_RESERVATIONS
    });
}

export const getReservations = () => async (dispatch, getState) => {
    try {
        const { document: { document } } = getState();
        dispatch(isLoading(true));

        let res = await Api.get('/document/reservation/', { docId: document._id });

        dispatch(isLoading(false));

        if (res && res.success) {
            dispatch(setReservations(res.documents));
        } else {
            toast('Wystąpił błąd');
        }
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const deleteDocument = (id, history) => async (dispatch) => {
    try {
        dispatch(isLoading(true));

        let res = await Api.delete(`/document/document/${id}`)
        if (res.success) {
            toast("Usunięto dokument");
            history.push("/documents");
        } else {
            toast("Wystąpił błąd");
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
        toast("Wystąpił błąd");
    }
}

export const saveQuickEdit = (data) => async (dispatch, getState) => {
    const { document: { document } } = getState();

    dispatch(isLoading(true));

    let res = await Api.put(`/document/quickEdit/${document._id}`, data);

    if (res && res.success) {
        let newDocument = JSON.parse(JSON.stringify(document));
        if ('info' in data) {
            newDocument.info = data.info;
        }
        if ('description' in data) {
            newDocument.description = data.description;
        }
        if ('note' in data) {
            newDocument.note = data.note;
        }
        if ('transportType' in data) {
            newDocument.transportType = data.transportType;
        }

        if ('transportDescription' in data) {
            newDocument.transportDescription = data.transportDescription;
        }

        if ('packagesAmount' in data) {
            newDocument.packagesAmount = data.packagesAmount;
        }

        dispatch(setDocument(newDocument));
        toast("Zapisano zmiany");
    } else {
        toast("Błąd zapisu");
    }

    dispatch(isLoading(false));
}

export const handleGenerateDocumentCodes = () => async (dispatch, getState) => {
    try {
        const { document: { document } } = getState();

        dispatch(isLoading(true));

        let res = await Api.get(`/document/document/generateDocumentCodes/${document._id}`, {}, "text");

        dispatch(isLoading(false));

        if (res) {
            var blob = new Blob([res], {
                type:
                    "application/pdf"
            });

            FileSaver.saveAs(blob, "kody-produktowe.pdf");
            toast('Pobrano kody produktowe');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setPrintOptions = (value, name) => (dispatch) => {
    dispatch({
        type: SET_PRINT_OPTIONS,
        value: value,
        name: name
    });
}

export const resetPrintOptions = () => (dispatch) => {
    dispatch({
        type: RESET_PRINT_OPTIONS
    });
}

export const undoDocument = (history) => async (dispatch, getState) => {
    try {
        const { document: { document } } = getState();

        dispatch(isLoading(true));

        let res = await Api.put(`/document/document/undoDocument/${document._id}`, {});

        dispatch(isLoading(false));

        if (res && res.success) {
            history.push("/documents");
            toast('Wycofano dokument');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const saveAdvancedEdit = (history) => async (dispatch, getState) => {
    try {
        const { document: { document } } = getState();

        dispatch(isLoading(true));

        let data = JSON.parse(JSON.stringify(document));

        data = _.pick(data, ["products", "salesDate", "operationDate", "deadline", "info", "note", "description", "transportType", "transportDescription", "packagesAmount", "customer", "products"])

        let res = await Api.put(`/document/advancedEdit/${document._id}`, data);

        dispatch(isLoading(false));

        if (res && res.success) {
            history.push(`/documents/details/${document._id}`);
            toast('Zapisano zmiany');
        } else {
            toast('Wystąpił błąd');
        }

    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const setFieldValue = (name, value) => (dispatch, getState) => {
    try {
        const { document: { document } } = getState();
        let newDocument = JSON.parse(JSON.stringify(document));

        _.set(newDocument, name, value);
        dispatch(setDocument(newDocument));
    } catch (err) {
        dispatch(isLoading(false));
        toast('Wystąpił błąd');
    }
}

export const getSuggestedSeries = (warehouseCode, productId) => async (dispatch, getState) => {
    const { document: { reservations } } = getState();
    dispatch(isLoading(true));

    let response = await Api.get(`/product/product/getProductPositions/${productId}`, { warehouse: warehouseCode })
    if (response && response.success == true) {
        if (response.documents) {
            let suggestedSeries = response.documents;

            suggestedSeries = suggestedSeries.map((series) => {
                let productReservation = _.find(reservations, (reservation) => {
                    return reservation.productId === productId;
                });

                if (productReservation) {
                    _.each(productReservation.series, (reservationSeries) => {
                        if (reservationSeries.seriesNr === series.seriesNr) {
                            series.amount -= reservationSeries.amount;
                        }
                    });
                }
                return series;
            });
            dispatch(setSuggestedSeries(response.documents));
        }
    }
    dispatch(isLoading(false));
}

export const setSuggestedSeries = (series) => (dispatch) => {
    dispatch({
        type: SET_SUGGESTED_SERIES,
        series: series
    });
}

export const clearSuggestedSeries = () => (dispatch) => {
    dispatch({
        type: CLEAR_SUGGESTED_SERIES
    });
}

export const changeReservationAmount = (product, suggestedSeries, amount) => async (dispatch, getState) => {
    const { document: { reservations, document } } = getState();
    let newReservations = JSON.parse(JSON.stringify(reservations));
    let reservationIndex = _.findIndex(newReservations, (r) => r.productId === product.productId)
    if (reservationIndex > -1) {
        let seriesIndex = _.findIndex(newReservations[reservationIndex].series, (reservationSeries) => {
            return reservationSeries.seriesNr === suggestedSeries.seriesNr && reservationSeries.documentSeriesNr === product.series.seriesNr && suggestedSeries.position === reservationSeries.positionCode;
        });
        if (seriesIndex > -1) {
            if (amount === 0 || isNaN(amount)) {
                newReservations[reservationIndex].series.splice(seriesIndex, 1);
            } else {
                newReservations[reservationIndex].series[seriesIndex].amount = amount.toString();
            }
        } else {
            newReservations[reservationIndex].series.push({
                seriesNr: suggestedSeries.seriesNr,
                documentSeriesNr: product.series.seriesNr,
                amount: amount,
                subtracts: true,
                recommended: [],
                position: suggestedSeries.positionId,
                positionCode: suggestedSeries.position
            })
        }
    } else {
        newReservations.push({
            docId: document._id,
            productId: product.productId,
            ean: product.ean,
            series: [
                {
                    seriesNr: suggestedSeries.seriesNr,
                    documentSeriesNr: product.series.seriesNr,
                    amount: amount,
                    subtracts: true,
                    recommended: [],
                    position: suggestedSeries.positionId,
                    positionCode: suggestedSeries.position
                }
            ]
        })
    }
    dispatch(setReservations(newReservations));
}

export const saveManualCompletion = (history) => async (dispatch, getState) => {
    try {
        const { document: { document, reservations } } = getState();
        dispatch(isLoading(true));

        let isValid = true;
        _.each(document.products, (product) => {
            _.each(product.series, (series) => {
                let sumCompleted = 0;
                _.each(reservations, (reservation) => {
                    if (reservation.productId === product.productId) {
                        _.each(reservation.series, (reservationSeries) => {
                            if (reservationSeries.documentSeriesNr === series.seriesNr) {
                                sumCompleted += parseFloat(reservationSeries.amount);
                            }
                        });
                    }
                })
                let amountLeft = _.round(Math.abs(series.amount) - sumCompleted, 5);
                if (amountLeft > 0) {
                    isValid = false;
                }
            });
        });
        if (!isValid) {
            dispatch(isLoading(false));
            toast('Nie skompletowano wszystkich produktów');
            return;
        }

        let statusRes = document.status === 1 ? await Api.put(`/document/document/changeStatus/${document._id}`, { status: 2 }) : { success: true };
        if (statusRes && statusRes.success) {
            let results = await Promise.all(reservations.map(async (reservation) => {
                let reservationRes = null;
                if (reservation._id) {
                    reservationRes = await Api.put(`/document/reservation/${reservation._id}`, reservation);
                } else {
                    reservationRes = await Api.post("/document/reservation", reservation);
                }
                if (reservationRes && reservationRes.success) {
                    return true;
                } else {
                    return false;
                }
            }));

            if (results.includes(false)) {
                dispatch(getReservations());
                toast('Błąd zapisu rezerwacji stanu');
                return;
            }

            let completionRes = await Api.put(`/document/document/saveCompleted/${document._id}`, { description: "Kompletacja manualna" });
            if (completionRes && completionRes.success) {
                toast('Zapisano dokument');
                history.push("/documents");
            } else {
                toast('Błąd finalizacji kompletacji');
                dispatch(getReservations());
            }
        } else {
            toast('Błąd zmiany statusu dokumentu');
        }
        dispatch(isLoading(false));
    } catch (err) {
        toast('Błąd zapisu kompletacji');
        dispatch(isLoading(false));
        dispatch(getReservations());
    }
}