import { toast } from 'react-toastify';
import { isLoading } from '../../application/actions/application.actions';
import Api from '../../services/api';

const namespace = 'SETTINGS';
export const SET_ITEM = `SET_ITEM_${namespace}`;
export const CLEAR_ITEM = `CLEAR_ITEM_${namespace}`;
export const SET_DIRTY = `SET_DIRTY_${namespace}`;

export const getItem = (type) => async (dispatch) => {
    try {
        dispatch(isLoading(true));
        let res = await Api.get("/application/dictionary", { type: type });

        if (res && res.success) {
            if (res.documents.length > 0) {
                let document = res.documents[0];
                document.values = document.values.map((value) => {
                    value.protected = true;
                    return value;
                });
                dispatch(setItem(document));
            } else {
                dispatch(setItem({
                    type: type,
                    values: []
                }));
            }
        } else {
            toast("Wystąpił błąd");
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
        toast("Wystąpił błąd");
    }
}

export const setItem = (item) => dispatch => {
    dispatch({
        type: SET_ITEM,
        item: item
    });
}

export const clearItem = () => (dispatch) => {
    dispatch({
        type: CLEAR_ITEM
    });
}

export const setDirty = (isDirty) => dispatch => {
    dispatch({
        type: SET_DIRTY,
        isDirty: isDirty
    });
}

export const addValue = () => (dispatch, getState) => {
    const { settings: { item } } = getState();

    let newItem = JSON.parse(JSON.stringify(item));

    newItem.values.push({
        name: "",
        value: ""
    });

    dispatch(setItem(newItem));
}

export const deleteValue = (index) => (dispatch, getState) => {
    const { settings: { item } } = getState();

    let newItem = JSON.parse(JSON.stringify(item));

    newItem.values.splice(index, 1);

    dispatch(setItem(newItem));
}

export const setValue = (value, name, index) => (dispatch, getState) => {
    const { settings: { item } } = getState();

    let newItem = JSON.parse(JSON.stringify(item));

    newItem.values[index][name] = value;

    dispatch(setItem(newItem));
    dispatch(setDirty(true));
}

export const setValues = (values) => (dispatch, getState) => {
    const { settings: { item } } = getState();

    let newItem = JSON.parse(JSON.stringify(item));

    newItem.values = values;

    dispatch(setItem(newItem));
    dispatch(setDirty(true));
}

export const saveItem = () => async (dispatch, getState) => {
    try {
        dispatch(isLoading(true));
        const { settings: { item } } = getState();

        let data = JSON.parse(JSON.stringify(item));

        data.values = data.values.map((value) => {
            delete value.protected;
            return value;
        });

        let res = data._id ? await Api.put(`/application/dictionary/${data._id}`, data) : await Api.post("/application/dictionary", data);

        if (res && res.success) {
            dispatch(setItem(null));
            dispatch(setDirty(false));
            toast("Zapisano");
        } else {
            toast("Wystąpił błąd");
        }
        dispatch(isLoading(false));
    } catch (err) {
        dispatch(isLoading(false));
        toast("Wystąpił błąd");
    }

}

