import produce from 'immer';

import { SET_ITEM, CLEAR_ITEM, SET_DIRTY } from '../actions/settings.actions'

const initial_state = {
    item: null,
    isDirty: false
}

const settingsReducer = (state = initial_state, action) => {
    return produce(state, draft => {
        switch (action.type) {
            case SET_ITEM:
                draft.item = action.item;
                break;
            case CLEAR_ITEM:
                draft.item = null;
                break;
            case SET_DIRTY:
                draft.isDirty = action.isDirty;
                break;
            default: return draft;
        }
    })
}

export default settingsReducer;