import _ from 'lodash';
import moment from "moment";
import React from 'react';
import ComponentNavbar from '../../application/components/componentNavbar';
import DictManager from '../../services/dict.service';
import SingleDetail from '../../application/components/singleDetail';
import { toast } from 'react-toastify';

export default class DocumentCompletion extends React.Component {

    componentDidMount = () => {
        const { getDocument } = this.props;
        const { id } = this.props.match.params;
        getDocument(id, true);
    }

    state = {
        currentProduct: null,
        showModal: false
    }

    getStatusValue = (status) => {
        switch (status) {
            case '1':
                return 'Do realizacji';
            case '2':
                return 'W realizacji';
            case '3':
                return 'Zrealizowany';
            default:
                return null;
        }
    }

    getDocumentDetails = () => {
        const { document: { document } } = this.props;
        if (!document) {
            return null;
        }

        const status = _.get(document, 'status');
        const statusValue = this.getStatusValue(status);
        const documentNumber = _.get(document, 'documentNumber')
        const info = _.get(document, 'info');
        const note = _.get(document, 'note');
        const createdAt = _.get(document, 'createdAt');
        const creator = _.get(document, 'creator.name');
        let transportType = _.get(document, 'transportType');
        if (transportType) {
            transportType = DictManager.getNameByValue(2, transportType);
        }
        const transportDescription = _.get(document, 'transportDescription');
        const warehouseFrom = _.get(document, 'warehouseFrom');
        const warehouseTo = _.get(document, 'warehouseTo');
        const customer = _.get(document, 'customer');
        const correctionInfo = _.get(document, "correctionInfo");

        return (
            <div className="mb-5">
                <div className="document-single-details-container">
                    <SingleDetail
                        label={'Status dokumentu'}
                        value={statusValue}
                    />
                    <SingleDetail
                        label={'Numer dokumentu'}
                        value={documentNumber}
                    />
                    {correctionInfo && <SingleDetail
                        label={'Korekta do'}
                        value={correctionInfo.documentNumber}
                    />}
                    {creator && <SingleDetail
                        label={'Dodany przez'}
                        value={creator}
                    />}

                    {createdAt && <SingleDetail
                        label={'Data dodania'}
                        value={moment(createdAt).format("YYYY-MM-DD HH:mm")}
                    />}
                    {warehouseFrom && warehouseFrom.name && <SingleDetail
                        label={'Magazyn'}
                        value={warehouseFrom.name}
                    />}
                    {warehouseTo && warehouseTo.name && <SingleDetail
                        label={'Magazyn powiązany'}
                        value={warehouseTo.name}
                    />}
                </div>
                <div className="mt-2 mb-2">
                    <SingleDetail
                        label={'Opis dokumentu'}
                        value={info}
                    />
                </div>
                {note && <div className="mt-2 mb-2">
                    <SingleDetail
                        label={'Uwagi'}
                        value={note}
                    />
                </div>}

                {transportDescription && <div className="mt-2 mb-2">
                    <SingleDetail
                        label={'Opis transportu'}
                        value={transportDescription}
                    />
                </div>}

                {customer && customer.name &&
                    <div>
                        <div className="document-details-address-box-container">
                            {customer && customer.name && <SingleDetail
                                smallFont={true}
                                label={'Kontrahent'}
                                value={customer.name}
                            />}
                            {customer && customer.address && <SingleDetail
                                smallFont={true}
                                label={'Adres'}
                                value={
                                    `${_.get(customer.address, 'postCode', '')} ${_.get(customer.address, 'city', '')}, ${_.get(customer.address, 'street', '')} ${_.get(customer.address, 'houseNumber', '')}`
                                }
                            />}
                            {customer && customer.nip && <SingleDetail
                                smallFont={true}
                                label={'NIP'}
                                value={customer.nip}
                            />}
                            {customer && customer.phone && <SingleDetail
                                smallFont={true}
                                label={'Telefon'}
                                value={
                                    `${_.get(customer, 'phone', '')}  ${_.get(customer, 'phone2', '')}`
                                }
                            />}
                            {customer && customer.fax && <SingleDetail
                                smallFont={true}
                                label={'Fax'}
                                value={customer.fax}
                            />}
                            {customer && customer.mail && <SingleDetail
                                smallFont={true}
                                label={'E-mail'}
                                value={customer.mail}
                            />}
                        </div>
                    </div>
                }
            </div>
        )
    }

    validateAmount = (suggestedSeries, newAmount, oldAmount) => {
        const { changeReservationAmount } = this.props;
        const { product } = this.state;
        oldAmount = _.round(parseFloat(oldAmount), 5);
        newAmount = _.round(parseFloat(newAmount), 5);
        let newLeft = _.round(product.amountLeft + oldAmount - newAmount, 5);
        let newCompleted = _.round(product.sumCompleted - oldAmount + newAmount, 5);
        if (!isNaN(newAmount) && newLeft >= 0 && newAmount <= suggestedSeries.amount) {
            changeReservationAmount(product, suggestedSeries, newAmount);
            this.setState({
                product: Object.assign({}, product, {
                    sumCompleted: newCompleted,
                    amountLeft: newLeft
                })
            })
        }
    }

    getSeriesModal = () => {
        const { document: { reservations, suggestedSeries }, clearSuggestedSeries, changeReservationAmount } = this.props;
        const { product, showModal } = this.state;

        if (!showModal || !product) return null;

        return (
            <div className="modal" role="dialog" style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', zIndex: 100000, display: 'flex', flex: 1 }}>
                <div className="modal-dialog modal-lg" role="document" style={{ width: "80vw" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Dodaj serie</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => {
                                    this.setState({ product: null, showModal: false });
                                    clearSuggestedSeries();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h5>Pozostało do skompletowania: {product.amountLeft} z {product.series.amount}</h5>
                            {_.isEmpty(suggestedSeries) ?
                                <p>Brak dostepnych serii</p> :
                                suggestedSeries.map((s, index) => {
                                    let amount = "0";
                                    let reservationIndex = _.findIndex(reservations, (r) => r.productId === product.productId)
                                    if (reservationIndex > -1) {
                                        let seriesIndex = _.findIndex(reservations[reservationIndex].series, (reservationSeries) => {
                                            return s.position === reservationSeries.positionCode && s.seriesNr === reservationSeries.seriesNr;
                                        });
                                        if (seriesIndex > -1) {
                                            amount = reservations[reservationIndex].series[seriesIndex].amount.toString();
                                        }
                                    }

                                    return (
                                        <div className="row" key={`suggested${index}`}>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label>Pozycja</label>
                                                    <input type="text" value={s.position} disabled={true} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label>Seria</label>
                                                    <input type="text" value={s.seriesNr} disabled={true} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label>Dostepna ilość</label>
                                                    <input type="text" value={s.amount} disabled={true} className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="form-group">
                                                    <label>Dodaj ilość</label>
                                                    <input type="number" value={amount} className="form-control" min="0" onChange={(e) => this.validateAmount(s, e.target.value, amount)} step={product.amountLeft > 1 ? "1" : "0.1"} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                        </div>
                        <div className="modal-footer">
                            <button
                                style={{ width: '150px' }}
                                type='button'
                                className='btn btn-primary'
                                onClick={() => {
                                    this.setState({ product: null, showModal: false });
                                    clearSuggestedSeries();
                                }}
                            > Zamknij </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProducts() {
        const { document: { document, reservations }, getSuggestedSeries } = this.props;

        return document.products.map((product, productIndex) => {
            return product.series.map((series, seriesIndex) => {

                let sumCompleted = 0;
                let chips = [];
                _.each(reservations, (reservation) => {
                    if (reservation.productId === product.productId) {
                        _.each(reservation.series, (rs, i) => {
                            if (rs.documentSeriesNr === series.seriesNr) {
                                sumCompleted += parseFloat(rs.amount);
                                chips.push(<li className="list-group-item" key={`${reservation.productId}${i}`}>Pozycja: <b>{rs.positionCode}</b> Seria: <b>{rs.seriesNr}</b> Ilość: <b>{rs.amount}</b></li>)
                            }
                        });
                    }
                })
                let amountLeft = _.round(Math.abs(series.amount) - sumCompleted, 5)
                return (<div className="card" key={`product${productIndex}`}>
                    <div className={`card-header ${amountLeft === 0 ? "bg-success" : ""}`} role="tab" id="headingOne">
                        <h5 className="mb-0">
                            <a data-toggle="collapse" href={`#p${productIndex}s${seriesIndex}`} aria-expanded="true" aria-controls="collapseOne" className="text-dark">
                                <div className="row">
                                    <div className="col-4">
                                        <b>{product.name}</b>
                                    </div>
                                    <div className="col-3">
                                        Seria: {series.seriesNr}
                                    </div>
                                    <div className="col-2">
                                        Ilość: {series.amount}
                                    </div>
                                    <div className="col-2">
                                        Skompletowano: {sumCompleted}
                                    </div>
                                </div>
                            </a>
                        </h5>
                    </div>

                    <div id={`p${productIndex}s${seriesIndex}`} className="collapse" role="tabpanel" aria-labelledby="headingOne">
                        <div className="card-body">
                            <div className="row text-center">
                                <div className="col-12">
                                    {_.isEmpty(chips) ? "Brak serii" : <ul className="list-group text-left">
                                        <h6>Skompletowanie serie</h6>
                                        {chips}
                                    </ul>}
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-secondary btn-sm mt-2" onClick={() => {
                                        this.setState({
                                            product: Object.assign({}, product, {
                                                series: series,
                                                sumCompleted,
                                                amountLeft
                                            }), showModal: true
                                        });
                                        getSuggestedSeries(_.get(document, "warehouseFrom.code"), product.productId);
                                    }}>Wybierz zasób</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            });
        });
    }

    render() {
        const { document: { document }, saveManualCompletion, history } = this.props;
        let seriesModal = this.getSeriesModal();
        if (!document) {
            return null;
        }

        const products = this.renderProducts();

        return (<div className="container mb-5">
            <ComponentNavbar
                title="Ręczna kompletacja dokumentu"
                button={null}
            />
            {seriesModal}
            <div className="row">
                <div className="col-12">
                    {this.getDocumentDetails()}
                </div>

                <div className="col-12 text-center">
                    <h5>Produkty</h5>
                    <div id="accordion" role="tablist">
                        {products}
                    </div>
                </div>
                <div className="col-12 text-center">
                    <hr />
                    <button className="btn btn-primary" onClick={() => saveManualCompletion(history)}>Skompletuj dokument</button>
                </div>
            </div>
        </div>)
    }

    componentWillUnmount() {
        const { clearDocument } = this.props;
        clearDocument();
    }
}