import React from 'react';
import { connect } from 'react-redux';
import { getRole, clearRole, setPermission, changeName, saveRole, setOnlyOwnDocuments, setFilter } from '../actions/role.action'
import Role from '../components/role.component';

class RoleContainer extends React.Component {
    render() {
        return (
            <div>
                <Role {...this.props} />
            </div>
        )
    }
}

function mapStateToProps(store) {
    return {
        role: store.role.role,
        saved: store.role.saved,
        isAdmin: store.auth.isAdmin
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRole: (id) => {
            dispatch(getRole(id));
        },
        clearRole: () => {
            dispatch(clearRole());
        },
        setPermission: (permission, action) => {
            dispatch(setPermission(permission, action));
        },
        changeName: (name) => {
            dispatch(changeName(name));
        },
        saveRole: () => {
            dispatch(saveRole());
        },
        setOnlyOwnDocuments: (permission) => {
            dispatch(setOnlyOwnDocuments(permission));
        },
        setFilter: (value, name, permission) => {
            dispatch(setFilter(value, name, permission));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleContainer);